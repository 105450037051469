<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark" style="font-size: 16px;">QUESTIONS DES TELESPECTATEURS</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/listeutilisateur">Modérateur</a></li>
                    <li class="breadcrumb-item active">Invité du jour</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
      <div class="col-md-12">

        <div class="card card-widget widget-user">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <div class="widget-user-header" style="background-color: #E6AD1F;">
              <h3 class="widget-user-username text-white" style="font-weight: 500;">{{Vinv_nomprenoms | uppercase}}</h3>
              <h5 class="widget-user-desc text-white">Invité du jour</h5>
            </div>
            <div class="widget-user-image">
              <img class="img-circle elevation-2" src="{{image}}" alt="User Avatar" style="height: 100px; width: 100px; margin-right: 20px;">
            </div>
            <div class="card-footer"><!-- style="height: 1500px;"-->
              <div class="row">
                <div class="card card-outline card-dark col-12" style="margin-top: 3px; height: 1500px;">
                    <h2 style="text-align: center; margin: 15px; background-color: blanchedalmond; padding: 15px;">QUESTIONS DES TELESPECTATEURS - <a style="color: rgb(233, 34, 34);">{{nbreQuestion}} Question(s)</a> </h2>
                     <!-- DIRECT CHAT -->
                     <!-- /.card-header -->
                     <div style=" margin: 15px;">

                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->

                        <!-- Conversations are loaded here -->
                        <div class="direct-chat-messages" style="height: 1300px;"> 
                          <!-- Message. Default to the left -->
                         
                          <div class="direct-chat-msg" *ngFor="let question of listequestion" style="font-size: x-large;" style="background-color: #3d352ac0; padding: 5px;">
                            <div class="direct-chat-infos clearfix" style="font-size: x-large; margin: 10px;">
                              <span class="direct-chat-name float-left text-white" style="margin-right: 5px;">{{question.qst_pseudo}}</span>
                              <span style="color: #fff;"> [ </span>
                              <span class="direct-chat-name  text-white"> {{question.qst_pays}} </span>
                              <span style="color: #fff;"> ] </span>
                              <span class="direct-chat-timestamp float-right text-white">{{question.qst_dateanimateur | date: 'dd/MM/yyyy'}} - {{question.qst_dateanimateur.substring(10)}}</span><!-- - {{question.qst_date.substring(10, 0)}}-->
                            </div>
                            <!-- /.direct-chat-infos -->
                            <img class="direct-chat-img" src="assets/images.png" alt="message user image">
                            <!-- /.direct-chat-img -->
                            <div class="direct-chat-text text-dark" style="background-color: #fff; padding: 10px; font-size: 20px; font-weight: 500;">
                             {{question.qst_question}}
                            </div>
                            <!--div  class="icheck-primary">
                                <input type="checkbox" value="" name="todo1" id="todoCheck1" style="font-size: x-large; margin-left: 15px;">
                                <label for="todoCheck1"></label>
                            </div-->
                            <!-- /.direct-chat-text -->
                          </div>
                          <!-- /.direct-chat-msg -->
                        </div>
                        <!--/.direct-chat-messages-->
                    
                      </div>
                      <!-- /.card-body -->
                      <!--/.direct-chat -->
                    </div>
              </div>
              <!-- /.row -->
            </div>
          </div>
        <!-- /.col -->

      </div>
     
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>

