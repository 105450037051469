import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { FooterComponent } from './footer/footer.component';
import { ListeutilisateursComponent } from './listeutilisateurs/listeutilisateurs.component';
import { TableaudebordComponent } from './tableaudebord/tableaudebord.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ListeinviteComponent } from './listeinvite/listeinvite.component';
import { EditioninviteComponent } from './editioninvite/editioninvite.component';
import { ModerateurComponent } from './moderateur/moderateur.component';
import { AnimateurComponent } from './animateur/animateur.component';
import { ListeinvitevotingComponent } from './listeinvitevoting/listeinvitevoting.component';
import { EditionparticipantComponent } from './editionparticipant/editionparticipant.component';
import { SuiviComponent } from './suivi/suivi.component';
import { EditionsuiviComponent } from './editionsuivi/editionsuivi.component';
import { ListemanchesComponent } from './listemanches/listemanches.component';
import { EditionmancheComponent } from './editionmanche/editionmanche.component';
import { ControlComponent } from './control/control.component';

const routes: Routes = [
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
  {
    path: 'admin', component: AdminComponent, children: [
      { path: '', redirectTo: 'listeinvite', pathMatch: 'full' },
      { path: 'tableaudebord', component: TableaudebordComponent },
      { path: 'footer', component: FooterComponent },
      { path: 'listeutilisateurs', component: ListeutilisateursComponent },
      { path: 'utilisateur/:id', component: UtilisateurComponent },
      { path: 'contacts', component: ContactsComponent },
      { path: 'listeinvite', component: ListeinviteComponent },
      { path: 'editioninvite/:id', component: EditioninviteComponent },
      { path: 'moderateur', component: ModerateurComponent },
      { path: 'animateur', component: AnimateurComponent },
      { path: 'listeinvitevote', component: ListeinvitevotingComponent },
      { path: 'editionparticipant/:id', component: EditionparticipantComponent },
      { path: 'suivi', component: SuiviComponent },
      { path: 'control', component: ControlComponent },
      { path: 'editionsuivi/:id', component: EditionsuiviComponent },
       { path: 'manches', component: ListemanchesComponent },
      { path: 'editionmanche/:id', component: EditionmancheComponent }
    ]
  },
  { path: '**', redirectTo: 'admin' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
