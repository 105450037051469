<!-- Content Header (Page header) -->
<div class="content-header " [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark" style="font-size: 16px;">SUIVI DES INTERVENTIONS</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item active">Suivi</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="card">
                    <div class="card-header" style="background-color: #9e9e9e; color: #FFFF; height: 50px;  ">
                        <h3 class="card-title" style="font-size: 30px; ">Liste des participants C'MIDI</h3>

                    </div>
                    <!-- /.card-header -->
                    <div class="w3-row w3-center w3-padding-24">
                        <label for="date">Date de passage : </label>
                        <input type="date" class="w3-margin-left" [(ngModel)]="dateChoisi">
                        <button class="w3-button w3-yellow w3-round-xlarge w3-margin-left"
                            (click)="listeParticipants()">Afficher les
                            participants</button>
                        <!--button class="w3-button w3-yellow w3-round-xlarge w3-margin-left" routerLink='/admin/admin/editionsuivi/0'>Afficher les
                                participants</button-->
                    </div>

                    <mat-form-field class="w3-margin-left w3-margin-right">
                        <mat-label>Rechercher un participant</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Information recherchée" #input>
                    </mat-form-field>


                    <div class="card-body">
                        <!-- loader  -->
                        <div class="spinner4" *ngIf="isLoadingResults">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <!-- loader end -->

                        <table class="table table-bordered table-striped dataTable" mat-table [dataSource]="dataSource"
                            matSort>
                            <!-- ID Column -->

                            <ng-container matColumnDef="votedu">
                                <th *matHeaderCellDef mat-sort-header style="width: 15%;"> <b>Vote du</b>
                                </th>
                                <td *matCellDef="let data">
                                    <button routerLink='/statmanager/{{data.manchevote_id}}/{{data.id}}' type="button"
                                        class="btn btn-info   btn-xs" style="margin:5px ;">
                                        <i class="fa fa-tachometer-alt"></i>
                                    </button>

                                    {{data.date | date:"dd/MM/yyyy"}}
                                </td>
                            </ng-container>

                            <!-- Progress Column -->
                            <ng-container matColumnDef="nomparticipant">
                                <th *matHeaderCellDef mat-sort-header style="width: 25%;"> Participant </th>
                                <td *matCellDef="let data">
                                    {{data.nomprenoms}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="theme">
                                <th *matHeaderCellDef mat-sort-header style="width: 30%;">
                                    Thème du jour</th>
                                <td *matCellDef="let data">
                                    {{data.theme}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="rang">
                                <th *matHeaderCellDef mat-sort-header>
                                    Ordre de passage</th>
                                <td *matCellDef="let data" class="w3-center">
                                    {{data.rangpassage}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="encours">
                                <th *matHeaderCellDef mat-sort-header>Encours</th>
                                <td *matCellDef="let data" style="text-align: center; color:green">
                                    <i *ngIf="data.actif == 'true'" class="fas fa-check"></i>
                                </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="boutons">
                                <th *matHeaderCellDef mat-sort-header style="width: 5px;"> Activer</th>
                                <td *matCellDef="let data" class="w3-center">
                                    <div class="btn-group">


                                        <button (click)="question(data.id)" type="button"
                                            class="btn  btn-success btn-xs" style="margin-left: 5px;"
                                            data-toggle="modal" data-target="#modal-info"
                                            *ngIf="data.actif=='null' || data.actif=='false' || data.actif=='' ">
                                            <i class="fas fa-check"></i></button>


                                        <button routerLink='/admin/admin/editionsuivi/{{data.manchevote_id}}'
                                            type="button" class="btn  btn-primary btn-xs" *ngIf="data.actif=='true'">
                                            <i class="fas fa-edit"></i></button>


                                        <button routerLink='/statnew/{{data.manchevote_id}}/{{data.id}}' type="button"
                                            class="btn btn-danger   btn-xs" style="margin-left:5px ;">
                                            <i class="fa fa-tachometer-alt"></i>
                                        </button>
                                        <button routerLink='/statpresentateur/{{data.manchevote_id}}/{{data.id}}'
                                            type="button" class="btn btn-info   btn-xs" style="margin-left:5px ;">
                                            <i class="fa fa-tachometer-alt"></i>
                                        </button>

                                    </div>
                                </td>
                            </ng-container>
                            <!-- Color Column -->


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">Aucune données "{{input.value}}"
                                </td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>

<!-- /.modal -->
<div class="modal fade" id="modal-info">
    <div class="modal-dialog">
        <div class="modal-content bg-info">
            <div class="modal-header" style="height: 50px;">
                <h2 class="modal-title">CMIDI </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <p>Voulez-vous activer le participant ?</p>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-outline-light" data-dismiss="modal">Annuler</button>
                <button type="button" class="btn btn-danger" (click)="activation()"
                    data-dismiss="modal">Activer</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->