<section class="content-header" [@simpleFadeAnimation]="'in'">
    <div>
        <!--class="content-wrapper"-->


        <section class="content">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-lg-3 col-6">
                        <div class="card card-warning card-outline">
                            <div class="card-body box-profile">
                                <div class="text-center">
                                    <img class="profile-user-img img-fluid" src="{{image}}" alt="User profile picture"
                                        style="width:180px ;">
                                </div>
                                <h2 class="inner profile-username text-center font-weight-bold">{{partic[0].nomprenoms}}
                                </h2>
                                <!--div class="text-muted text-center">Software Engineer</div-->
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-9 col-6">
                        <div class="card card-warning card-outline">
                            <div class="card-body box-profile">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="text-left">
                                            <h2 class="inner profile-username text-left font-weight-bold text-danger">
                                                THEME</h2>

                                            <h2 class="inner profile-username text-left font-weight-bold">
                                                {{partic[0].theme}}</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="text-right">
                                            <img src="assets/imgx/logo.png" alt="User profile picture"
                                                style="width:180px ;">
                                        </div>
                                    </div>

                                    <div class="col-md-12 text-center text-info" style="padding-top: 10px;">
                                        <span class="font-weight-bold display-1 ">{{pourcentage}}<sup
                                                class="display-4">%</sup></span>
                                    </div>
                                </div>






                            </div>

                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">PARTICIPANTS</span>
                                <span class="info-box-number numberTotal">{{nbreTotal}}</span>
                            </div>

                        </div>

                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">EN STUDIO</span>
                                <span class="info-box-number numberTotal">{{nbreTotalStudio}}</span>
                            </div>

                        </div>

                    </div>

                    <div class="col-12 col-sm-6 col-md-2">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">TELESPECTATEURS</span>
                                <span class="info-box-number numberTotal">{{nbreTotalTelespectateur}}</span>
                            </div>

                        </div>

                    </div>


                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-success elevation-1"><i class="fas fa-thumbs-up"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">Total</span>
                                <span class="info-box-number numberTotal">{{nbreJaime}}</span>
                            </div>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">Studio</span>
                                <span class="info-box-number numberTotal">{{nbreJaimeStudio}}</span>
                            </div>

                            <div class="info-box-content">
                                <span class="info-box-text numberText">Téléspectateurs</span>
                                <span class="info-box-number numberTotal">{{nbreJaimeTelespectateur}}</span>
                            </div>

                        </div>

                    </div>

                    <div class="col-12 col-sm-6 col-md-3">
                        <div class="info-box mb-3">
                            <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-down"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">Total</span>
                                <span class="info-box-number numberTotal">{{nbreJaimePas}}</span>
                            </div>
                            <div class="info-box-content">
                                <span class="info-box-text numberText">Studio</span>
                                <span class="info-box-number numberTotal">{{nbreJaimePasStudio}}</span>
                            </div>

                            <div class="info-box-content">
                                <span class="info-box-text numberText">Téléspectateurs</span>
                                <span class="info-box-number numberTotal">{{nbreJaimePasTelespectateur}}</span>
                            </div>

                        </div>

                    </div>


                    <div class="clearfix hidden-md-up"></div>
                </div>


                <div class="row">

                    <div class="col-md-2">

                        <div class="card card-widget widget-user">

                            <div class="widget-user-header text-white" style="background-color:#0A58CA ;">
                                <h3 class="widget-user-username">HOMMES</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="assets/imgx/hommeV.jpeg" alt="User Avatar">
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header numberTotalhf">Total</h5>
                                            <span class="description-text numberTotalhf">{{pourcentageHomme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header"><i class="fas fa-thumbs-up text-success"></i>
                                            </h5>
                                            <span
                                                class="description-text numberTotalhf">{{pourcentageJaimeHomme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4">
                                        <div class="description-block">
                                            <h5 class="description-header"><i
                                                    class="fas fa-thumbs-down text-danger"></i></h5>
                                            <span
                                                class="description-text numberTotalhf">{{pourcentageJaimePasHomme}}%</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="col-md-2">

                        <div class="card card-widget widget-user">

                            <div class="widget-user-header text-white" style="background-color: #D63384;">
                                <h3 class="widget-user-username">FEMMES</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="assets/imgx/femmeV.jpeg" alt="User Avatar">
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header numberTotalhf">Total</h5>
                                            <span class="description-text numberTotalhf">{{pourcentageFemme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header"><i class="fas fa-thumbs-up text-success"></i>
                                            </h5>
                                            <span
                                                class="description-text numberTotalhf">{{pourcentageJaimeFemme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4">
                                        <div class="description-block">
                                            <h5 class="description-header"><i
                                                    class="fas fa-thumbs-down text-danger"></i></h5>
                                            <span
                                                class="description-text numberTotalhf">{{pourcentageJaimePasFemme}}%</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>


                    <div class="col-md-4">

                        <div class="card card-danger card-outline">
                            <div class="card-header bg-info">
                                <h2 class="card-title">
                                    <i class="far fa-chart-bar"></i>
                                    PARTICIPANTS PAR PAYS
                                </h2>
                            </div>
                            <div class="card-body">
                                <figure class="highcharts-figure">

                                    <!-- loader  -->
                                    <div class="spinner4" *ngIf="isLoadingResults">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>

                                    <div id="container"></div>
                                </figure>
                            </div>

                        </div>



                    </div>

                    <div class="col-md-4">

                        <div class="card card-danger card-outline">
                            <div class="card-header bg-info">
                                <h2 class="card-title">
                                    <i class="far fa-chart-bar"></i>
                                    PARTICIPANTS PAR TRANCHE D'AGE
                                </h2>
                            </div>
                            <div class="card-body">
                                <figure class="highcharts-figure">

                                    <!-- loader  -->
                                    <div class="spinner4" *ngIf="isLoadingResultsSite">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>


                                    <!-- /.card-body -->
                                    <div id="containerSite"></div>
                                </figure>
                            </div>

                        </div>



                    </div>



                </div>

            </div>
        </section>

    </div>

</section>