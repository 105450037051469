<body class="hold-transition register-page" [@simpleFadeAnimation]="'in'" style="background-image: url('assets/dist/img/Technical-Support2.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center;">
    <div class="register-box">
        <div class="card  card-outline card-success">
            <div class="card-header" style="background-color: rgb(233, 231, 231);">
                <div class="login-logo">
                    <img src="assets/dist/img/RTIlogo.png" alt="RTI Logo" class="brand-image  elevation-3" height="40px">
                    <a style="color: black;"> - NEO</a>
                </div>
            </div>
            <div class="card-body register-card-body">
                <h2 style="text-align: center; color:coral">Activation de compte</h2>
                <!-- loader  -->
                <div class="spinner4" *ngIf="isLoadingResults">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
                <!-- loader end -->
                <form [formGroup]="registerFormGroup" (ngSubmit)="verificationmdp(registerFormGroup.value)">

                    <div class="input-group mb-3">
                        <input type="email" class="form-control" placeholder="Email" formControlName='Util_Email' required>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>

                    <!--   <div [ngClass]="{'afficheerror': erreurOld === true}" class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Mot de passe"
                            formControlName='Util_Mdp'>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>

                    <div [ngClass]="{'afficheerror': erreurOld === true}" class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Vérification du Mot de passe"
                            formControlName='Util_RMdp'>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>-->


                    <div class="row">

                        <!-- /.col -->
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block btn-flat" [disabled]="!registerFormGroup.valid">Enregistrer</button>
                        </div>
                        <!-- /.col -->

                    </div>
                    <div *ngIf="affichexit" class="row;" style="font-size: 15px; color: red; text-align:center;">
                        <a> Votre compte est déjà activé dans NEO</a>
                    </div>
                    <div *ngIf="affichok" class="row;" style="font-size: 15px; color: rgb(5, 56, 12); text-align:center;">
                        <a> BRAVO, Votre compte est activé dans NEO, Cliquez sur "J'ai déjà un compte" votre mot de passe est : <b>123</b></a>
                    </div>
                </form>

            </div>
            <!-- /.form-box -->
            <div class="card-footer">
                <p class="mt-2">
                    <a routerLink="/auth" class="text-center">J'ai déjà un compte</a>
                </p>
            </div>
        </div>
        <!-- /.card -->
    </div>
    <!-- /.register-box -->

</body>