<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">EDITION PARTICIPANTS</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/admin/admin/listeinvitevote">Liste des participants</a>
                    </li>
                    <li class="breadcrumb-item active">Edition participants</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!--    <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i> Note:</h5>
                    Selectionnez dans la liste l'agent que vous voulez activer !!!
                </div>


                 Main content -->
                <!-- /.card -->

                <div class="card">
                    <div class="card-header" style="background-color: #086A87; color: #FFFF; height: 50px  ">
                        <h3 class="card-title">Edition Participant</h3>
                        <button routerLink='/admin/admin/listeinvitevote' type="button"
                            class="btn btn-danger float-sm-right" style="margin-top: -5px;">Retourner à la
                            liste</button>

                    </div>
                    <!-- /.card-header -->

                    <div class="col-md-12">
                        <!-- general form elements -->
                        <div class="card card-outline card-info" style="margin-top: 3px;">
                            <!-- form start -->
                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->


                            <form [formGroup]="participantForm" (ngSubmit)="onSubmitForm(participantForm.value)">
                                <div class="w3-row">
                                    <div class="w3-half w3-container">
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Date de l'émission <a
                                                                style="color: red; margin-right: 5px;">*</a>
                                                            <i class="fa fa-check w3-green w3-animate-zoom"
                                                                *ngIf="trouve"></i>
                                                            <i class="fa fa-exclamation-circle w3-red w3-animate-zoom"
                                                                *ngIf="!trouve"></i>
                                                        </label>
                                                        <input type="text" class="form-control"
                                                            formControlName="manchevote_id" hidden>
                                                        <input type="date" class="form-control" formControlName="date"
                                                            (change)="choixDate($event.target.value)"
                                                            [readonly]="moidf">
                                                        <input type="text" class="form-control" formControlName="id"
                                                            hidden>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Rang de passage <a style="color: red;">*</a></label>
                                                        <input type="number" class="form-control"
                                                            formControlName="rangpassage">


                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Nom et Prénoms du participant <a
                                                                style="color: red;">*</a></label>
                                                        <input type="text" class="form-control"
                                                            formControlName="nomprenoms"
                                                            placeholder="Identification du participant">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Thème developpé <a style="color: red;">*</a></label>
                                                        <input type="text" class="form-control" formControlName="theme"
                                                            placeholder="Inscrivez le thème ici">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="poste">Photo <a style="color: red;">*</a></label>
                                                        <input type="file" class="form-control"
                                                            formControlName="lienphoto"
                                                            (change)="handleFileInput($event.target.files)"
                                                            multiple="false" />
                                                        <input type="text" class="form-control"
                                                            formControlName="lienphotoTxt" hidden />
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group" style="margin-top:30px ;">
                                                        <label class="w3-padding">Encours</label>
                                                        <input class="w3-check" type="checkbox" formControlName="actif">

                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    <div class="w3-half w3-container">
                                        <div class="row " *ngIf="image">
                                            <div class="image" style="margin: auto;">
                                                <img src="{{image}}" class="elevation-2" alt="User Image"
                                                    style="height: 250px; width: 250px;margin-top: 40px;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer">
                                    <div class="w3-container w3-center">
                                        <div class="form-group">
                                            <button class="btn btn-success" type="submit"
                                                [disabled]="!participantForm.valid">Enregistrer</button>
                                            <button class="btn btn-danger" type="text"
                                                routerLink='/admin/admin/listeinvitevote'
                                                style="margin-left: 10px;">Quitter
                                                la fiche</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- /.card -->


                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>