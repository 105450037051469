import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MdpoublieComponent } from './auth/mdpoublie/mdpoublie.component';
import { ModifmotdepasseComponent } from './auth/modifmotdepasse/modifmotdepasse.component';
import { RegisterComponent } from './auth/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { StatNewComponent } from './stat-new/stat-new.component';
import { StatComponent } from './stat/stat.component';
import { StatmanagerComponent } from './statmanager/statmanager.component';
import { StatpresentateurComponent } from './statpresentateur/statpresentateur.component';


const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'tbvote', loadChildren: () => import('./tbvote/tbvote.module').then(m => m.TbvoteModule) },
  { path: 'mdpoublie', component: MdpoublieComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'stat/:id/:ids', component: StatComponent },
  { path: 'statnew/:id/:ids', component: StatNewComponent },
  { path: 'statmanager/:id/:ids', component: StatmanagerComponent },
  { path: 'statpresentateur/:id/:ids', component: StatpresentateurComponent },
  { path: 'modifmdp', component: ModifmotdepasseComponent },
  { path: '**', redirectTo: 'auth' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
