<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark" style="font-size: 16px;">MODERATEUR</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/listeutilisateur">Modérateur</a></li>
                    <li class="breadcrumb-item active">Invité du jour</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
      <div class="col-md-12">

        <div class="card card-widget widget-user">
            <!-- Add the bg color to the header using any of the bg-* classes -->
            <div class="widget-user-header" style="background-color: #78909C;">
              <h3 class="widget-user-username text-white" style="font-weight: 500;">{{Vinv_nomprenoms | uppercase}}</h3>
              <h5 class="widget-user-desc text-white">Invité du jour</h5>
            </div>
            <div class="widget-user-image">
              <img class="img-circle elevation-2" src="{{image}}" alt="User Avatar" style="height: 100px; width: 100px; margin-right: 20px;">
            </div>
            <div class="card-footer"><!-- style="height: 1500px;"-->
              <div class="row">
                <div class="card card-outline card-dark col-12" style="margin-top: 3px; height: 1500px;">
                    <h2 style="text-align: center; margin: 15px; background-color: blanchedalmond; padding: 15px;">QUESTIONS DES TELESPECTATEURS - <a style="color: rgb(233, 34, 34);">{{nbreQuestion}} Question(s)</a> </h2>
                     <!-- DIRECT CHAT -->
                     <!-- /.card-header -->
                     <div style=" margin: 15px;">

                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->

                        <!-- Conversations are loaded here -->
                        <div class="direct-chat-messages" style="height: 1300px;"> 
                          <!-- Message. Default to the left -->
                         
                          <div class="direct-chat-msg" *ngFor="let question of listequestion" 
                          [ngStyle]="{'background-color':question.qst_valid=='false'  ? '#EF9A9A' : '#C8E6C9' }"
                          style="font-size: x-large;" 
                          style="background-color: #3d352ac0; padding: 5px;">
                            <div class="direct-chat-infos clearfix" style="font-size: x-large; margin: 10px;">
                              <span class="direct-chat-name float-left " style="margin-right: 5px;"> {{question.qst_pseudo}} </span>
                              <span> [ </span>
                              <span class="direct-chat-name  "> {{question.qst_pays}} </span>
                              <span> ] </span>
                              <span class="direct-chat-timestamp float-right ">{{question.qst_date | date: 'dd/MM/yyyy'}} - {{question.qst_date.substring(10)}}</span><!-- - {{question.qst_date.substring(10, 0)}}-->
                            </div>
                            <!-- /.direct-chat-infos -->
                            <img class="direct-chat-img" src="assets/images.png" alt="message user image">
                            <!-- /.direct-chat-img -->
                            <div class="direct-chat-text text-dark" [ngStyle]="{'background-color':question.qst_valid=='false'  ? '#EF9A9A' : '#C8E6C9' }"
                            style="padding: 10px; font-size: 20px; font-weight: 500;">
                             {{question.qst_question}}
                            </div>
                            <div  class="icheck-primary" style="margin: 5px;" >
                              <!--div class="spinner5" *ngIf="isLoadingResultsval">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div-->
                                <button type="button" class="btn-success float-right" (click)="changeetat(question.qst_id)" *ngIf="question.qst_valid=='false' ">Valider</button>
                                <button type="button" class="btn-danger float-right" (click)="changeetat(question.qst_id)" *ngIf="question.qst_valid=='true' ">Retirer</button>
                              </div>
                            <!-- /.direct-chat-text -->
                          </div>
                          <!-- /.direct-chat-msg -->
                        </div>
                        <!--/.direct-chat-messages-->
                    
                      </div>
                      <!-- /.card-body -->
                      <!--/.direct-chat -->
                    </div>
              </div>
              <!-- /.row -->
            </div>
          </div>
        <!-- /.col -->

      </div>
     
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>

