import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigService } from '../../services/config.service';
import { VoteService } from 'src/app/services/vote.service';

@Component({
  selector: 'app-editionmanche',
  templateUrl: './editionmanche.component.html',
  styleUrls: ['./editionmanche.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class EditionmancheComponent implements OnInit {

  mancheForm: FormGroup;
  isLoadingResults = false;
  unemanche: any = [];
  id: string;
  afficheReinit = false;
  reponse: any;
  image: string = "";
  
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private voteService: VoteService,
    private configService: ConfigService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder) { 
      this.mancheForm = this.formBuilder.group({
        id: [''],
        actif: [''],
        date: ['', Validators.required], 
        fermeStudio: ['']
      });
    }

  ngOnInit() {
    this.loadOneManche();
  }



  loadOneManche() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.isLoadingResults = true;
    if (this.id !== '0') {
      this.voteService.mancheParID(this.id).subscribe(reponse => {
        this.unemanche = reponse;

        this.mancheForm.controls['id'].setValue(this.unemanche.results[0].id);
        this.mancheForm.controls['date'].setValue(this.unemanche.results[0].date);
        if (this.unemanche.results[0].actif !== 'true') {
          this.mancheForm.controls['actif'].setValue(false);
        } else {
          this.mancheForm.controls['actif'].setValue(true);
        }
        if (this.unemanche.results[0].fermeStudio !== 'true') {
          this.mancheForm.controls['fermeStudio'].setValue(false);
        } else {
          this.mancheForm.controls['fermeStudio'].setValue(true);
        }
      });
        this.isLoadingResults = false;
    } else {
        this.isLoadingResults = false;
    }
  }

  onSubmitForm(f) {
      this.isLoadingResults = true;
      const mancheFormData = new FormData();
    mancheFormData.append('date', f.date);
    if (f.actif == true ) {
       mancheFormData.append('actif', 'true');
    } else {
       mancheFormData.append('actif', 'false');
    }
     if (f.fermeStudio == true ) {
       mancheFormData.append('fermeStudio', 'true');
    } else {
       mancheFormData.append('fermeStudio', 'false');
    }
   

      if (this.id === '0') {
        this.voteService.creationmanche(mancheFormData).subscribe(result => {
          switch (result.success) {
            case true: {
              // statements;
              this.toastr.success(result.message);
              this.reinitform();
              break;
            }
            case false: {
              // statements;
              this.toastr.error(result.message);
              break;
            }
            default: {
              // statements;
              this.toastr.error(result.message);
              break;
            }

          }
          this.isLoadingResults = false;

        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
        });
      } else {
        mancheFormData.append('id', f.id);
        this.voteService.modificationManche(mancheFormData).subscribe(result => {
          switch (result.success) {
            case true: {
              // statements;
              this.toastr.success(result.message);
              break;
            }
            case false: {
              // statements;
              this.toastr.error(result.message);
              break;
            }
            default: {
              // statements;
              this.toastr.error(result.message);
              break;
            }

          }
          this.isLoadingResults = false;
          this.location.back();
          //this.router.navigate(['/listeutilisateur']);
        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
        });
      }
  }

  reinitform() {
    this.mancheForm.reset();
  }


}