<!-- Content Header (Page header) -->
<div class="content-header " [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark" style="font-size: 16px;">ENREGISTREMENTS DES INVITÉS</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item active">Invités</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">

                <div class="card">
                    <div class="card-header" style="background-color: #212121; color: #FFFF; height: 50px;  ">
                        <h3 class="card-title" style="font-size: 30px; ">Liste des invités C'MIDI</h3>
                        <button routerLink='/admin/admin/editioninvite/0' class="btn float-sm-right"
                            style="margin-top: -5px; background-color: #c1ab24; color: #ffff; ">Ajouter un
                            invité</button>
                    </div>
                    <!-- /.card-header -->

                    <mat-form-field style="padding: 30px; margin-top: -20px">
                        <mat-label>Rechercher un invité</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Information recherchée" #input>
                    </mat-form-field>


                    <div class="card-body" style="margin-top: -50px;">
                        <!-- loader  -->
                        <div class="spinner4" *ngIf="isLoadingResults">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                        <!-- loader end -->

                        <table class="table table-bordered table-striped dataTable" mat-table [dataSource]="dataSource"
                            matSort>
                            <!-- ID Column -->
                            <ng-container matColumnDef="date">
                                <th *matHeaderCellDef mat-sort-header style="width: 10%;"> <b>Date</b>
                                </th>
                                <td *matCellDef="let data"> {{data.inv_date | date:"dd/MM/yyyy"}} </td>
                            </ng-container>

                            <!-- Progress Column -->
                            <ng-container matColumnDef="nominvite">
                                <th *matHeaderCellDef mat-sort-header style="width: 30%;"> Invité du jour </th>
                                <td *matCellDef="let data">
                                    {{data.inv_nomprenoms}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="theme">
                                <th *matHeaderCellDef mat-sort-header style="width: 30%;">
                                    Thème du jour</th>
                                <td *matCellDef="let data">
                                    {{data.inv_theme}}
                                </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="cel">
                                <th *matHeaderCellDef mat-sort-header>
                                    Téléphone</th>
                                <td *matCellDef="let data">
                                    {{data.inv_cel}}
                                </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="email">
                                <th *matHeaderCellDef mat-sort-header>
                                    Email</th>
                                <td *matCellDef="let data">
                                    {{data.inv_mail}}
                                </td>
                            </ng-container>
                            <!-- Name Column -->
                            <ng-container matColumnDef="encours">
                                <th *matHeaderCellDef mat-sort-header>Encours</th>
                                <td *matCellDef="let data" style="text-align: center; color:green">
                                    <i *ngIf="data.inv_actif == 'true'" class="fas fa-check"></i>
                                </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="boutons">
                                <th *matHeaderCellDef mat-sort-header style="width: 5px;"> </th>
                                <td *matCellDef="let data">
                                    <div class="btn-group"> <button
                                            routerLink='/admin/admin/editioninvite/{{data.inv_id}}' type="button"
                                            class="btn  btn-primary btn-xs">
                                            <i class="fas fa-edit"></i></button>
                                        <button (click)="questionSupprime(data.inv_id)" type="button"
                                            class="btn  btn-danger btn-xs" style="margin-left: 5px;" data-toggle="modal"
                                            data-target="#modal-info">
                                            <i class="fas fa-trash"></i></button>
                                    </div>
                                </td>
                            </ng-container>
                            <!-- Color Column -->


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let data; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">Aucune données "{{input.value}}"
                                </td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>

<!-- /.modal -->
<div class="modal fade" id="modal-info">
    <div class="modal-dialog">
        <div class="modal-content bg-info">
            <div class="modal-header" style="height: 50px;">
                <h2 class="modal-title">C'MIDI </h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <p>Voulez-vous supprimer l'enregistrement ?</p>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-outline-light" data-dismiss="modal">Annuler</button>
                <button type="button" class="btn btn-danger" (click)="supprime()"
                    data-dismiss="modal">Supprimer</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->