import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { InviteService } from '../../services/invite.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-editioninvite',
  templateUrl: './editioninvite.component.html',
  styleUrls: ['./editioninvite.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class EditioninviteComponent implements OnInit {

  userForm: FormGroup;
  isLoadingResults = false;
  uninvite: any = [];
  id: string;
  afficheReinit = false;
  reponse: any;
  fileToUpload: File = null;
 // image: string = "assets/dist/img/images.png";
  image: string = "";
  
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private inviteService: InviteService,
    private configService: ConfigService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder) { 
      this.userForm = this.formBuilder.group({
        inv_id: [''],
        inv_actif: [''],
        inv_date: ['', Validators.required],
        inv_cel: [''],
        inv_lienphoto: [''],
        inv_mail: ['', Validators.email],
        inv_nomprenoms: ['', Validators.required],
        inv_theme: ['', Validators.required],
        inv_lienphotoTxt: ['', Validators.required],
        inv_heuredeb: ['',Validators.required],
        inv_heurefin: ['',Validators.required]
      });
    }

  ngOnInit() {
    this.loadOneInvite();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.userForm.controls['inv_lienphotoTxt'].setValue(files.item(0));
  }

  loadOneInvite() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.isLoadingResults = true;
    if (this.id !== '0') {
      this.inviteService.inviteparid(this.id).subscribe(reponse => {
        this.uninvite = reponse;

        this.userForm.controls['inv_id'].setValue(this.uninvite.results[0].inv_id);
        this.userForm.controls['inv_cel'].setValue(this.uninvite.results[0].inv_cel);
        this.userForm.controls['inv_date'].setValue(this.uninvite.results[0].inv_date);
        this.userForm.controls['inv_mail'].setValue(this.uninvite.results[0].inv_mail);
        this.userForm.controls['inv_nomprenoms'].setValue(this.uninvite.results[0].inv_nomprenoms);
        this.userForm.controls['inv_theme'].setValue(this.uninvite.results[0].inv_theme);
        this.userForm.controls['inv_lienphotoTxt'].setValue(this.uninvite.results[0].inv_lienphoto);
        this.userForm.controls['inv_heuredeb'].setValue(this.uninvite.results[0].inv_heuredeb);
        this.userForm.controls['inv_heurefin'].setValue(this.uninvite.results[0].inv_heurefin);
        this.image = this.configService.urlg+'/'+this.uninvite.results[0].inv_lienphoto;
        if (this.uninvite.results[0].inv_actif !== 'true') {
          this.userForm.controls['inv_actif'].setValue(false);
        } else {
          this.userForm.controls['inv_actif'].setValue(true);
        }
      });
        this.isLoadingResults = false;
    } else {
        this.isLoadingResults = false;
    }
  }

  onSubmitForm(f) {
      this.isLoadingResults = true;
      const userFormData = new FormData();
      userFormData.append('inv_cel', f.inv_cel);
      userFormData.append('inv_date', f.inv_date);
      userFormData.append('inv_mail', f.inv_mail);
      userFormData.append('inv_nomprenoms', f.inv_nomprenoms);
      userFormData.append('inv_theme', f.inv_theme);
      userFormData.append('inv_heuredeb', f.inv_heuredeb);
      userFormData.append('inv_heurefin', f.inv_heurefin);
      //userFormData.append('inv_lienphoto', f.inv_lienphoto);
      userFormData.append('inv_actif', f.inv_actif);

      if (this.fileToUpload == null) {
        userFormData.append('inv_lienphoto', this.fileToUpload);
      } else {
        userFormData.append('inv_lienphoto', this.fileToUpload, this.fileToUpload.name);
      }


      if (this.id === '0') {
        this.inviteService.creationinvite(userFormData).subscribe(result => {
          switch (result.success) {
            case true: {
              // statements;
              this.toastr.success(result.message);
              this.reinitform();
              break;
            }
            case false: {
              // statements;
              this.toastr.error(result.message);
              break;
            }
            default: {
              // statements;
              this.toastr.error('Erreur de connexion au serveur');
              break;
            }

          }
          this.isLoadingResults = false;

        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
        });
      } else {
        userFormData.append('inv_id', f.inv_id);
        this.inviteService.msjinvite(userFormData).subscribe(result => {
          switch (result.success) {
            case true: {
              // statements;
              this.toastr.success(result.message);
              break;
            }
            case false: {
              // statements;
              this.toastr.error(result.message);
              break;
            }
            default: {
              // statements;
              this.toastr.error('Erreur de connexion au serveur');
              break;
            }

          }
          this.isLoadingResults = false;
          this.location.back();
          //this.router.navigate(['/listeutilisateur']);
        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
        });
      }


  }

  reinitform() {
    this.userForm.reset();
    /*this.userForm.controls['Util_Nomprenoms'].reset();
    this.userForm.controls['Util_Email'].reset();
    this.userForm.controls['Util_tech'].reset();
    this.userForm.controls['Util_Direction'].reset();
    this.userForm.controls['Util_telephone'].reset();
    this.userForm.controls['Util_Poste'].reset();
    this.userForm.controls['Util_IDDirection'].reset();
    this.userForm.controls['Util_Admin'].reset();
    this.userForm.controls['Util_BP'].reset();*/
  }


}