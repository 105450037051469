<div class="wrapper" [@simpleFadeAnimation]="'in'">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand  navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu"><i class="fas fa-bars"></i></a>
            </li>

        </ul>

    </nav>
    <!-- /.navbar -->


    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4" style="background-color: #212121;">
        <!-- Brand Logo -->
        <a routerLink='/admin/admin/tableaudebord' class="brand-link">
            <img src="assets/dist/img/LogoRTI2.png" alt="RTI Logo" class="brand-image  elevation-3" style="opacity: .8">
            <img src="assets/dist/img/logo-c-midi.png" alt="RTI Logo" class="brand-image  elevation-3"
                style="opacity: .8">
            <!--span class="brand-text font-weight-light">C'MIDI</span-->
        </a>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image" *ngIf="image">
                    <img src={{image}} class="img-circle elevation-2" alt="User Image">
                </div>
                <div class="image" *ngIf="!image">
                    <img src="assets/dist/img/images.png" class="img-circle elevation-2" alt="User Image">
                </div>

                <div class="info">
                    <a class="d-block">{{nomprenoms}}</a>

                    <a type="button" style="color: orange;" (click)='deconnexion()'>Se déconnecter</a>

                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <!-- Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library -->
                    <li class="nav-item" *ngIf="Administration">
                        <a routerLink='/admin/admin/listeinvite' class="nav-link">
                            <i class="fas fa-users nav-icon"></i>
                            <p>
                                Gestion des Invités
                            </p>
                        </a>
                    </li>
                    <!--li class="nav-item" *ngIf="moderateur">
                        <a routerLink='/admin/admin/tbmoderateur' class="nav-link">
                            <i class="far fa-file-pdf  nav-icon"></i>
                            <p>
                                Fiche question
                            </p>
                        </a>
                    </li-->
                    <li class="nav-item" *ngIf="moderateur">
                        <a routerLink='/admin/admin/moderateur' class="nav-link">
                            <i class="fas fa-certificate nav-icon"></i>
                            <p>
                                Modérateur
                            </p>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="animateur">
                        <a routerLink='/admin/admin/animateur' class="nav-link">
                            <i class="far fa-bookmark nav-icon"></i>
                            <p>
                                Animateur
                            </p>
                        </a>
                    </li>
                    <!--li class="nav-item has-treeview" *ngIf="Administration">
                        <a class="nav-link" style="cursor: pointer;">
                            <i class="nav-icon fas fa-copy"></i>
                            <p>
                                Gestion du Personnel
                                <i class="fas fa-angle-left right"></i>
                              
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a routerLink='/admin/admin/mesticketsencours' class="nav-link">

                                    <i class="fas fa-users nav-icon"></i>
                                    <p>Liste du Personnel</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink='/admin/admin/mesticketsfermes' class="nav-link">

                                    <i class="far fa-file-pdf  nav-icon"></i>
                                    <p>Gestion des Documents</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink='/admin/admin/mesticketsfermes' class="nav-link">
                                    <i class="far fa-bookmark nav-icon"></i>
                                    <p>Rapport Contrat Objectif</p>
                                </a>
                            </li>

                            <li class="nav-item">
                                <a routerLink='/admin/admin/mesticketsfermes' class="nav-link">
                                    <i class="fas fa-certificate nav-icon"></i>
                                    <p>Rapport Evaluation</p>
                                </a>
                            </li>
                        </ul>
                    </li-->

                    <li class="nav-item has-treeview " *ngIf="Administration">
                        <a style="cursor: pointer;" class="nav-link ">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Administration
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">

                            <li class="nav-item">
                                <a routerLink='/admin/admin/listeutilisateurs' class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>Utilisateurs</p>
                                </a>
                            </li>
                        </ul>
                    </li>


                    <li class="nav-item has-treeview " *ngIf="Administrationvote">
                        <a style="cursor: pointer;" class="nav-link ">
                            <i class="nav-icon fas fa-newspaper"></i>
                            <p>

                                Gestion des votes
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <a routerLink='/admin/admin/manches' class="nav-link">
                                    <i class="fas fa-chalkboard-teacher	nav-icon"></i>
                                    <p>Manches</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink='/admin/admin/listeinvitevote' class="nav-link">
                                    <i class="fas fa-chalkboard-teacher	nav-icon"></i>
                                    <p>Les participants</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink='/admin/admin/suivi' class="nav-link">
                                    <i class="fas fa-chalkboard-teacher	nav-icon"></i>
                                    <p>Suivi</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink='/tbvote/decompte' class="nav-link">
                                    <i class="fas fa-chalkboard-teacher	nav-icon"></i>
                                    <p>Decompte</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink='/admin/admin/control' class="nav-link">
                                    <i class="fas fa-chalkboard-teacher	nav-icon"></i>
                                    <p>Control</p>
                                </a>
                            </li>
                            <!--li class="nav-item">
                                <a routerLink='/tbvote/statistique/e31a7613-4301-11ed-9b79-eaa67e882e4c/78375508-4302-11ed-9b79-eaa67e882e4c'
                                    class="nav-link">
                                    <i class="fas fa-chalkboard-teacher	nav-icon"></i>
                                    <p>Statistique</p>
                                </a>
                            </li-->
                        </ul>
                    </li>

                </ul>
            </nav>

        </div>

    </aside>


    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Main content -->
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
        <!-- /.container-fluid -->
        <!-- /.content -->
    </div>


</div>