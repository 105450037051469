import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  //**
  //urlg = 'http://172.16.2.16:8071';
  urlg = 'https://cmidi.rti.ci/api';
  

  idUser = localStorage.getItem('id');
  var_Reattribution;
  MesTickets: boolean = false;
  MesAttributions: boolean = false;
  GestionTickets: boolean = false;
  Referentiel: boolean = false;
  Administration: boolean = false;

  constructor() { }
}
