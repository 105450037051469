<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">EDITION INVITÉ</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/listeutilisateur">Liste des invités</a></li>
                    <li class="breadcrumb-item active">Edition invité</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!--    <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i> Note:</h5>
                    Selectionnez dans la liste l'agent que vous voulez activer !!!
                </div>


                 Main content -->
                <!-- /.card -->

                <div class="card">
                    <div class="card-header" style="background-color: #086A87; color: #FFFF; height: 50px  ">
                        <h3 class="card-title">Edition invité</h3>
                        <button routerLink='/admin/admin/listeinvite' type="button" class="btn btn-danger float-sm-right" style="margin-top: -5px;">Retourner à la liste</button>

                    </div>
                    <!-- /.card-header -->

                    <div class="col-md-12">
                        <!-- general form elements -->
                        <div class="card card-outline card-info" style="margin-top: 3px;">
                            <!-- form start -->
                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->
                         

                            <form [formGroup]="userForm" (ngSubmit)="onSubmitForm(userForm.value)">
                               <div class="row">
                                    <div class="card-body " >
                                        <div class="row">
                                            <div class="col-4" >
                                                <div class="form-group">
                                                    <label>Date de l'émission <a style="color: red;">*</a></label>
                                                    <input type="text" class="form-control" formControlName="inv_id" placeholder="Id" hidden>
                                                    <input type="date" class="form-control" formControlName="inv_date">
                                                </div>
                                            </div>
                                            <div class="col-2" >
                                                
                                                <div class="form-group">
                                                    <label>Heure Début <a style="color: red;">*</a></label>
                                                    <input type="time" class="form-control" formControlName="inv_heuredeb" >
                                                </div>
                                                
                                            </div>
                                           
                                            <div class="col-2" >
                                                <div class="form-group">
                                                    <label>Heure Fin <a style="color: red;">*</a></label>
                                                    <input type="time" class="form-control" formControlName="inv_heurefin" >
                                                </div>
                                                
                                            </div>
                                            <div class="col-2" >
                                                <div class="form-group">
                                                    <label style="color: #FFFF;">--</label>
                                                    <div class="form-check">
                                                        <input type="checkbox" class="form-check-input" formControlName="inv_actif" id="inv_actif" style="font-size: 25px;">
                                                        <label class="form-check-label" for="inv_actif" style="font-size: 15px; color: green;"><b>ACTIF</b></label>
                                                </div>
                                                </div>
                                                
                                            </div>
                                         
                                       
                                        </div>
                                        <div class="row">
                                            <div class="col-12" >
                                            <div class="form-group">
                                                <label>Nom et Prénoms de l'invité <a style="color: red;">*</a></label>
                                                <input type="text" class="form-control"  formControlName="inv_nomprenoms" placeholder="Identification de l'invité">
                                            </div>
                                        </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-12" >
                                            <div class="form-group">
                                                <label>Thème de l'invité <a style="color: red;">*</a></label>
                                                <input type="text" class="form-control" formControlName="inv_theme" placeholder="Inscrivez le thème ici">
                                            </div>
                                            </div>
                                        </div>
                                    
                                        <div class="row">
                                            <div class="col-4" >
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Email </label>
                                                    <input type="email" class="form-control" formControlName="inv_mail" placeholder="Enter email" >
                                                </div>
                                            </div>
                                            <div class="col-4" >
                                                <div class="form-group">
                                                    <label for="tel">Téléphone</label>
                                                    <input type="text" class="form-control" formControlName="inv_cel" placeholder="Téléphone">
                                                </div>
                                            </div>
                                            <div class="col-4" >
                                                <div class="form-group">
                                                    <label for="poste">Lien de la Photo <a style="color: red;">*</a></label>
                                                    <input type="file" class="form-control" formControlName="inv_lienphoto"  (change)="handleFileInput($event.target.files)" multiple="false" />
                                                    <input type="text" class="form-control" formControlName="inv_lienphotoTxt" hidden/>
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row " *ngIf="image">
                                        <div class="image" style="margin: auto;">
                                            <img src="{{image}}" class="img-circle elevation-2" alt="User Image" style="height: 250px; width: 250px;">
                                        </div>
                                    </div>
                                </div>
                              
                                <!-- /.card-body -->
                                <div class="card-footer" >
                                    <div class="form-group">
                                        <button class="btn btn-success" type="submit" [disabled]="!userForm.valid">Enregistrer</button>
                                        <button class="btn btn-danger" type="text" routerLink='/admin/admin/listeinvite' style="margin-left: 10px;">Quitter la fiche</button> 
                                    </div>
                                   
                                </div>
                            </form>
                        </div>
                        <!-- /.card -->


                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>