import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe, Location } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
//import { EtatsService } from 'src/app/services/etats.service';
//import * as XLSX from 'xlsx';
import * as Highcharts from 'highcharts';
import { ConfigService } from 'src/app/services/config.service';
import { VoteService } from 'src/app/services/vote.service';
//import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-stat-new',
  templateUrl: './stat-new.component.html',
  styleUrls: ['./stat-new.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class StatNewComponent implements  OnInit {
 
  //----General-------
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  fileName;
  //----Fin General----
  

  
  //----Graph Agent-------
    cat = [];
    dataC = [];
    dataCp = [];
    data: any = [];
    listedata: any = [];
    displayedColumns: string[] = ['Nomprenoms', 'Total'];
    isLoadingResults = true;
    dataSource: MatTableDataSource<any>;
  public options: any = {
      Chart: {
         type: 'pie',
            options3d: {
                enabled: true,
                alpha: 95
            }
      },
      plotOptions: { 
        series: { dataLabels: { enabled: true } } ,
        pie: {
          innerSize: 50,
          depth: 95,
          allowPointSelect: true,
          cursor: 'pointer', 
        dataLabels: { enabled: true, color: '#000000', connectorWidth: 1, connectorColor: '#000000' } }
    },
    title: {
    text: 'Participants reparties par pays'
  },
  subtitle: {
    text: ''
  },
       series: [{
         type: 'pie',
          options3d: {
                enabled: true,
                alpha: 95
            },
        name: 'Total Avis',
        data: this.dataCp
      }],
    }
  //----Fin Graph Agent-------
  
  //----Graph Site-------
  catSite = [];
  dataCpSite = [];
  dataSite: any = [];
  listedataSite: any = [];
  displayedColumnsSite: string[] = ['age', 'Total'];
  isLoadingResultsSite = true;
  dataSourceSite: MatTableDataSource<any>;
  public optionsSite: any = {
    Chart: {
      type: 'column',
      redraw: true,
    },
    plotOptions: { series: { dataLabels: { enabled: true } } },
    title: {
      text: 'Avis par tranche d\'age'
    },
    credits: {
      enabled: true
    },
    xAxis: {
      categories: this.catSite,
      tickmarkPlacement: 'on',
      title: {
        enabled: true
      }
    },
    series: [{
      type: 'column',
      name: 'Total Avis',
      data: this.dataCpSite
    }]
  
  }
  //----Fin Graph Site-------
  
  //----Graph Direction-------
  catDirection = [];
  dataCpDirection = [];
  dataDirection: any = [];
  listedataDirection: any = [];
  displayedColumnsDirection: string[] = ['Direction', 'Total'];
  isLoadingResultsDirection = true;
  dataSourceDirection: MatTableDataSource<any>;
  public optionsDirection: any = {
    Chart: {
      type: 'column',
      redraw: true,
    },
    plotOptions: { series: { dataLabels: { enabled: true } } },
    title: {
      text: 'Nombre d\'intervention par direction'
    },
    credits: {
      enabled: true
    },
    xAxis: {
      categories: this.catDirection,
      tickmarkPlacement: 'on',
      title: {
        enabled: true
      }
    },
    series: [{
      type: 'column',
      name: 'Total Intervention',
      data: this.dataCpDirection
    }]
  }
  //----Fin Graph Direction-------
  
  //----Graph Categorie-------
  catCategorie = [];
  dataCpCategorie = [];
  dataCategorie: any = [];
  listedataCategorie: any = [];
  displayedColumnsCategorie: string[] = ['Categorie', 'Total'];
  isLoadingResultsCategorie = true;
  dataSourceCategorie: MatTableDataSource<any>;
  public optionsCategorie: any = {
    Chart: {
      type: 'bar',
      redraw: true,
    },
    plotOptions: { series: { dataLabels: { enabled: true } } },
    title: {
      text: 'Nombre d\'intervention par catégorie'
    },
    credits: {
      enabled: true
    },
    xAxis: {
      categories: this.catCategorie,
      tickmarkPlacement: 'on',
      title: {
        enabled: true
      }
    },
    series: [{
      type: 'bar',
      name: 'Total Intervention',
      data: this.dataCpCategorie
    }]
  }
  //----Fin Graph Categorie-------
  
  //----Graph Service-------
  catService = [];
  dataCpService = [];
  dataService: any = [];
  listedataService: any = [];
  displayedColumnsService: string[] = ['Service', 'Total'];
  isLoadingResultsService = true;
  dataSourceService: MatTableDataSource<any>;
  public optionsService: any = {
    Chart: {
      type: 'bar',
      redraw: true,
    },
    plotOptions: { series: { dataLabels: { enabled: true } } },
    title: {
      text: 'Nombre d\'intervention par service'
    },
    credits: {
      enabled: true
    },
    xAxis: {
      categories: this.catService,
      tickmarkPlacement: 'on',
      title: {
        enabled: true
      }
    },
    series: [{
      type: 'bar',
      name: 'Total Intervention',
      data: this.dataCpService
    }]
  }
  //----Fin Graph Service-------
  
  //----Graph Sousservice-------
  catSousservice = [];
  dataCpSousservice = [];
  dataSousservice: any = [];
  listedataSousservice: any = [];
  displayedColumnsSousservice: string[] = ['Sousservice', 'Total'];//
  isLoadingResultsSousservice = true;
  dataSourceSousservice: MatTableDataSource<any>;
  public optionsSousservice: any = {
    Chart: {
      type: 'bar',
      redraw: true,
    },
    plotOptions: { series: { dataLabels: { enabled: true } } },
    title: {
      text: 'Nombre d\'intervention par sous-service'
    },
    credits: {
      enabled: true
    },
    xAxis: {
      categories: this.catSousservice,
      tickmarkPlacement: 'on',
      title: {
        enabled: true
      }
    },
    series: [{
      type: 'bar',
      name: 'Total Intervention',
      data: this.dataCpSousservice
    }]
  }
  //----Fin Graph Sousservice-------

  id: any;
  ids: any;

  nbreTotal = 0;
  nbreJaime = 0;
  nbreJaimePas = 0;
  nbreFemme = 0;
  nbreHomme = 0;
  nbreSup18 = 0;
  nbreInf18 = 0;
  pourcentage = 0;
  pourcentageFemme = 0;
  pourcentageHomme = 0;

  mancheid: any;
  participantid: any;
  reponse: any;

  partic: any;
  image: any;

  nbreTotalStudio = 0;
  nbreTotalTelespectateur = 0;
  nbreJaimeStudio = 0;
  nbreJaimePasStudio = 0;
  nbreJaimeTelespectateur = 0;
  nbreJaimePasTelespectateur = 0;

  pourcentageJaimeHomme = 0;
  pourcentageJaimePasHomme = 0;
  pourcentageJaimeFemme = 0;
  pourcentageJaimePasFemme  = 0;

  pourcentageTotalStudio = 0;
  pourcentageTotalTelespectateur = 0;
  pourcentageJaimeStudio = "";
  pourcentageJaimePasStudio = "";
  pourcentageJaimeTelespectateur = "";
  pourcentageJaimePasTelespectateur = "";

  pourcentageJaime = '';
  pourcentageJaimePas = '';


    constructor( private datePipe: DatePipe,
     // private etatsService: EtatsService,
      private location: Location,
      private router: ActivatedRoute,
      private configService: ConfigService,
      private voteService: VoteService)
    { 
        this.dataSource = new MatTableDataSource(this.listedata);
        this.dataSourceSite = new MatTableDataSource(this.listedataSite);
        this.dataSourceDirection = new MatTableDataSource(this.listedataDirection);
        this.dataSourceCategorie = new MatTableDataSource(this.listedataCategorie);
        this.dataSourceService = new MatTableDataSource(this.listedataService);
        this.dataSourceSousservice = new MatTableDataSource(this.listedataSousservice);
       /* this.dateDebut = this.varDateDeb;
        this.dateFin = this.varDateFin;
        this.LavarDateDeb = "2000-01-01";//this.datePipe.transform(this.varDateDeb,"yyyyMMdd")
        this.LavarDateFin = this.datePipe.transform(this.varDateFin,"yyyy-MM-dd");*/
      }
  
  ngOnInit() {
    
    this.id = this.router.snapshot.paramMap.get('id');
    this.ids = this.router.snapshot.paramMap.get('ids');
    this.affichetb();
    this.participant();
       /*  this.lanceEtat();
      
        setInterval(() => this.affichetb(), 5600);*/
  }
  
  participant() {
    this.voteService.participantParID(this.router.snapshot.paramMap.get('ids')).subscribe(ret => {
      this.partic = ret.results;

        this.image = this.configService.urlg + '/' + this.partic[0].lienphoto;
    });
  }

  Raffraichitb() {
    this.voteService.statistique(this.id, this.ids).subscribe(ret => {
      this.reponse = ret;
    //  alert('tttrtrtrttt');
      this.nbreFemme = this.reponse.results.nbreFemme;
      this.nbreHomme = this.reponse.results.nbreHomme;
      this.nbreInf18 = this.reponse.results.nbreInf18;
      this.nbreJaime = this.reponse.results.nbreJaime;
      this.nbreJaimePas = this.reponse.results.nbreJaimePas;
      this.nbreSup18 = this.reponse.results.nbreSup18;
      this.nbreTotal = this.reponse.results.nbreTotal;
      this.pourcentage = this.reponse.results.pourcentage;
      this.pourcentageHomme = this.reponse.results.pourcentageHomme;
      this.pourcentageFemme = this.reponse.results.pourcentageFemme;

      this.nbreTotalStudio = this.reponse.results.nbreTotalStudio;
      this.nbreTotalTelespectateur = this.reponse.results.nbreTotalTelespectateur;
      this.nbreJaimeStudio = this.reponse.results.nbreJaimeStudio;
      this.nbreJaimePasStudio = this.reponse.results.nbreJaimePasStudio;
      this.nbreJaimeTelespectateur = this.reponse.results.nbreJaimeTelespectateur;
      this.nbreJaimePasTelespectateur = this.reponse.results.nbreJaimePasTelespectateur;
      this.pourcentageTotalStudio = this.reponse.results.pourcentageTotalStudio;
      this.pourcentageTotalTelespectateur = this.reponse.results.pourcentageTotalTelespectateur;
      this.pourcentageJaimeStudio = this.reponse.results.pourcentageJaimeStudio;
      this.pourcentageJaimePasStudio = this.reponse.results.pourcentageJaimePasStudio;
      this.pourcentageJaimeTelespectateur = this.reponse.results.pourcentageJaimeTelespectateur;
      this.pourcentageJaimePasTelespectateur = this.reponse.results.pourcentageJaimePasTelespectateur;

      this.pourcentageJaimeHomme = this.reponse.results.pourcentageJaimeHomme;
      this.pourcentageJaimePasHomme = this.reponse.results.pourcentageJaimePasHomme;
      this.pourcentageJaimeFemme = this.reponse.results.pourcentageJaimeFemme;
      this.pourcentageJaimePasFemme = this.reponse.results.pourcentageJaimePasFemme;

  
    });
  }

  retour() {
    this.location.back();
  }

  affichetb() {
          this.voteService.statistique(this.id , this.ids).subscribe(ret=>{
            this.reponse = ret;
            this.nbreFemme = this.reponse.results.nbreFemme;
            this.nbreHomme  = this.reponse.results.nbreHomme;
            this.nbreInf18 = this.reponse.results.nbreInf18;
             this.nbreJaime = this.reponse.results.nbreJaime;
            this.nbreJaimePas  = this.reponse.results.nbreJaimePas;
            this.nbreSup18 = this.reponse.results.nbreSup18;
             this.nbreTotal = this.reponse.results.nbreTotal;
            this.pourcentage = this.reponse.results.pourcentage;
            this.pourcentageHomme = this.reponse.results.pourcentageHomme;
            this.pourcentageFemme = this.reponse.results.pourcentageFemme;

            this.nbreTotalStudio = this.reponse.results.nbreTotalStudio;
            this.nbreTotalTelespectateur = this.reponse.results.nbreTotalTelespectateur;
            this.nbreJaimeStudio = this.reponse.results.nbreJaimeStudio;
            this.nbreJaimePasStudio = this.reponse.results.nbreJaimePasStudio;
            this.nbreJaimeTelespectateur = this.reponse.results.nbreJaimeTelespectateur;
            this.nbreJaimePasTelespectateur = this.reponse.results.nbreJaimePasTelespectateur;
            this.pourcentageTotalStudio = this.reponse.results.pourcentageTotalStudio;
            this.pourcentageTotalTelespectateur = this.reponse.results.pourcentageTotalTelespectateur;
            this.pourcentageJaimeStudio = this.reponse.results.pourcentageJaimeStudio;
            this.pourcentageJaimePasStudio = this.reponse.results.pourcentageJaimePasStudio;
            this.pourcentageJaimeTelespectateur = this.reponse.results.pourcentageJaimeTelespectateur;
            this.pourcentageJaimePasTelespectateur = this.reponse.results.pourcentageJaimePasTelespectateur;

            this.pourcentageJaimeHomme = this.reponse.results.pourcentageJaimeHomme;
            this.pourcentageJaimePasHomme = this.reponse.results.pourcentageJaimePasHomme;
            this.pourcentageJaimeFemme = this.reponse.results.pourcentageJaimeFemme;
            this.pourcentageJaimePasFemme = this.reponse.results.pourcentageJaimePasFemme;


            if (((Number(this.nbreJaime) + Number(this.nbreJaimePas))) > 0) {
                      this.pourcentageJaime =  ((Number(this.nbreJaime) * 100) / (Number(this.nbreJaime) + Number(this.nbreJaimePas))).toFixed(0);
                      this.pourcentageJaimePas =  ((Number(this.nbreJaimePas) * 100) / (Number(this.nbreJaime) + Number(this.nbreJaimePas))).toFixed(0);   
                } else {
                        this.pourcentageJaime = "0";
                        this.pourcentageJaimePas = "0";
                }
   
       
            
            if (((Number(this.nbreJaimeStudio) + Number(this.nbreJaimePasStudio))) > 0) {
               this.pourcentageJaimeStudio = ((Number(this.nbreJaimeStudio) * 100) / (Number(this.nbreJaimeStudio) + Number(this.nbreJaimePasStudio))).toFixed(0);
               this.pourcentageJaimePasStudio = ((Number(this.nbreJaimePasStudio) * 100) / (Number(this.nbreJaimeStudio) + Number(this.nbreJaimePasStudio))).toFixed(0);
     
            } else {
              this.pourcentageJaimeStudio = "0";
               this.pourcentageJaimePasStudio = "0";
     
            }

            if ((Number(this.nbreJaimeTelespectateur) + Number(this.nbreJaimePasTelespectateur)) > 0) {
                this.pourcentageJaimeTelespectateur = ((Number(this.nbreJaimeTelespectateur) * 100) / (Number(this.nbreJaimeTelespectateur) + Number(this.nbreJaimePasTelespectateur))).toFixed(0);
                this.pourcentageJaimePasTelespectateur = ((Number(this.nbreJaimePasTelespectateur) * 100) / (Number(this.nbreJaimeTelespectateur) + Number(this.nbreJaimePasTelespectateur))).toFixed(0);
      
            } else {
              this.pourcentageJaimeTelespectateur = "0";
                this.pourcentageJaimePasTelespectateur = "0";
      
            }
    
           
            
     // this.nbreJaimeStudio
     // this.nbreJaimeTelespectateur



            /////////// TOTAL
            this.isLoadingResults = false;
             this.data = ret;
            this.listedata = this.data.results;
            this.dataSource.data = this.listedata.resultatPays;
            
            this.listedata.resultatPays.forEach(element => {
            this.cat.push(element.pays);
            this.dataCp.push([element.pays, Number(element.nbrePays)]);
             }); 
            Highcharts.chart('container', this.options).redraw;
            this.isLoadingResults = false;
            /////////// FIN TOTAL


    /////////// TOTAL PAYS
        this.dataSite = ret;
        this.listedataSite = this.dataSite.results;
        this.dataSourceSite.data = this.listedataSite.resultatAge;
        this.isLoadingResultsSite = false;
        this.listedataSite.resultatAge.forEach(element => {
        this.catSite.push(element.libelle);
        this.dataCpSite.push(Number(element.nbre));
        }); 
       Highcharts.chart('containerSite', this.optionsSite).redraw;
            this.isLoadingResultsSite = false;

    /////////// FIN TOTAL PAYS

          });
       }
  
       /* exportexcel(): void
        {
          var date =  new Date();
          this.fileName= 'RapportAgent_'+ this.datePipe.transform(date,"ddMMyyyy") + '.xlsx';
           pass here the table id 
          let element = document.getElementById('excel-table');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       
           generate workbook and add the worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       
           save to file 
          XLSX.writeFile(wb, this.fileName);
       
        }*/
          
        /*  exportexcelSite(): void
        {
          var date =  new Date();
          this.fileName= 'RapportSite_'+ this.datePipe.transform(date,"ddMMyyyy") + '.xlsx';
         pass here the table id 
          let element = document.getElementById('excel-tableSite');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       
           generate workbook and add the worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       
           save to file 
          XLSX.writeFile(wb, this.fileName);
       
        }*/  
  
      /*  exportexcelDirection(): void
        {
          var date =  new Date();
          this.fileName= 'RapportDirection_'+ this.datePipe.transform(date,"ddMMyyyy") + '.xlsx';
           pass here the table id 
          let element = document.getElementById('excel-tableDirection');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       
           generate workbook and add the worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       
           save to file 
          XLSX.writeFile(wb, this.fileName);
       
        }*/  
  
       /* exportexcelCategorie(): void
        {
          var date =  new Date();
          this.fileName= 'RapportCategorie_'+ this.datePipe.transform(date,"ddMMyyyy") + '.xlsx';
           pass here the table id 
          let element = document.getElementById('excel-tableCategorie');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       
           generate workbook and add the worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       
           save to file  
          XLSX.writeFile(wb, this.fileName);
       
        }*/ 
       /*   exportexcelService(): void
        {
          var date =  new Date();
          this.fileName= 'RapportService_'+ this.datePipe.transform(date,"ddMMyyyy") + '.xlsx';
         pass here the table id 
          let element = document.getElementById('excel-tableService');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       
           generate workbook and add the worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       
           save to file  
          XLSX.writeFile(wb, this.fileName);
       
        }*/ 
     /*   exportexcelSousservice(): void
        {
          var date =  new Date();
          this.fileName= 'RapportSousservice_'+ this.datePipe.transform(date,"ddMMyyyy") + '.xlsx';
           pass here the table id 
          let element = document.getElementById('excel-tableSousservice');
          const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
       
           generate workbook and add the worksheet 
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       
           save to file 
          XLSX.writeFile(wb, this.fileName);
        }*/  
  
        ngAfterViewInit() {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.dataSourceSite.paginator = this.paginator;
          this.dataSourceSite.sort = this.sort;
          this.dataSourceDirection.paginator = this.paginator;
          this.dataSourceDirection.sort = this.sort;
          this.dataSourceCategorie.paginator = this.paginator;
          this.dataSourceCategorie.sort = this.sort;
          this.dataSourceService.paginator = this.paginator;
          this.dataSourceService.sort = this.sort;
          this.dataSourceSousservice.paginator = this.paginator;
          this.dataSourceSousservice.sort = this.sort;
        }
      
       /* applyFilter(event: Event) {
          const filterValue = (event.target as HTMLInputElement).value;
          this.dataSource.filter = filterValue.trim().toLowerCase();
      
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        }*/
      
       
    /*    lanceEtat(){
            this.isLoadingResults = true;
            this.isLoadingResultsSite = true;
            this.isLoadingResultsDirection = true;
            this.isLoadingResultsCategorie = true;
            this.isLoadingResultsService = true;
            this.isLoadingResultsSousservice = true;
  
            this.etatsService.interventionparAgent(this.LavarDateDeb, this.LavarDateFin).subscribe(ret=>{
              this.data = ret;
              this.listedata = this.data.results;
              this.dataSource.data = this.listedata.liste;
              this.isLoadingResults = false;
              this.listedata.liste.forEach(element => {
              this.cat.push(element.Util_Nomprenoms);
              this.dataCp.push([element.Util_Nomprenoms,Number(element.Total)]);
              }); 
             Highcharts.chart('container', this.options).redraw;
            
              this.isLoadingResults = false;
      });
  
      this.etatsService.interventionparSite(this.LavarDateDeb, this.LavarDateFin).subscribe(ret=>{
        this.dataSite = ret;
        this.listedataSite = this.dataSite.results;
        this.dataSourceSite.data = this.listedataSite.liste;
        this.isLoadingResultsSite = false;
        this.listedataSite.liste.forEach(element => {
        this.catSite.push(element.site);
        this.dataCpSite.push(Number(element.Total));
        }); 
       Highcharts.chart('containerSite', this.optionsSite).redraw;
        this.isLoadingResultsSite = false;
  });
  
  this.etatsService.interventionparDirection(this.LavarDateDeb, this.LavarDateFin).subscribe(ret=>{
    this.dataDirection = ret;
    this.listedataDirection = this.dataDirection.results;
    this.dataSourceDirection.data = this.listedataDirection.liste;
    this.isLoadingResultsDirection = false;
    this.listedataDirection.liste.forEach(element => {
    this.catDirection.push(element.Util_Direction);
    this.dataCpDirection.push(Number(element.Total));
    }); 
   Highcharts.chart('containerDirection', this.optionsDirection).redraw;
    this.isLoadingResultsDirection = false;
  });
  
  this.etatsService.interventionparCategorie(this.LavarDateDeb, this.LavarDateFin).subscribe(ret=>{
    this.dataCategorie = ret;
    this.listedataCategorie = this.dataCategorie.results;
    this.dataSourceCategorie.data = this.listedataCategorie.liste;
    this.isLoadingResultsCategorie = false;
    this.listedataCategorie.liste.forEach(element => {
    this.catCategorie.push(element.Categorie);
    this.dataCpCategorie.push(Number(element.Total));
    }); 
   Highcharts.chart('containerCategorie', this.optionsCategorie).redraw;
    this.isLoadingResultsCategorie = false;
  });
  
  this.etatsService.interventionparService(this.LavarDateDeb, this.LavarDateFin).subscribe(ret=>{
    this.dataService= ret;
    this.listedataService = this.dataService.results;
    this.dataSourceService.data = this.listedataService.liste;
    this.isLoadingResultsService = false;
    this.listedataService.liste.forEach(element => {
    this.catService.push(element.serv_service);
    this.dataCpService.push(Number(element.Total));
    }); 
   Highcharts.chart('containerService', this.optionsService).redraw;
    this.isLoadingResultsService = false;
  });
  
  this.etatsService.interventionparSousservice(this.LavarDateDeb, this.LavarDateFin).subscribe(ret=>{
    this.dataSousservice= ret;
    this.listedataSousservice = this.dataSousservice.results;
    this.dataSourceSousservice.data = this.listedataSousservice.liste;
    this.isLoadingResultsSousservice = false;
    this.listedataSousservice.liste.forEach(element => {
    this.catSousservice.push(element.sous_sousservice);
    this.dataCpSousservice.push(Number(element.Total));
    }); 
   Highcharts.chart('containerSousservice', this.optionsSousservice).redraw;
    this.isLoadingResultsSousservice = false;
  });
  
    }*/
  
    gotoback() {
      this.location.back();
    }
  }
  