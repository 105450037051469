<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">EDITION SUIVI</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/listeutilisateur">Suivi</a></li>
                    <li class="breadcrumb-item active">Edition suivi</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="w3-row">
            <div class="col-12">
                <!--    <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i> Note:</h5>
                    Selectionnez dans la liste l'agent que vous voulez activer !!!
                </div>


                 Main content -->
                <!-- /.card -->

                <div class="card">
                    <div class="card-header" style="background-color: #9e9e9e; color: #FFFF; height: 50px  ">
                        <h3 class="card-title">Edition Participant</h3>
                        <button routerLink='/admin/admin/suivi' type="button" class="btn btn-danger float-sm-right"
                            style="margin-top: -5px;">Retourner à la
                            liste</button>

                    </div>
                    <!-- /.card-header -->

                    <div class="col-md-12">
                        <!-- general form elements -->
                        <div class="card card-outline" style="margin-top: 3px;">
                            <!-- form start -->
                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->


                            <form [formGroup]="etapeForm" (ngSubmit)="onSubmitForm(etapeForm.value)">
                                <div class="w3-row w3-padding">
                                    <div class="w3-half w3-container">
                                        <h2 style="color: green;">CONFIGURATION DES ETAPES</h2>

                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Nom et Prénoms du participant </label>
                                                        <input type="text" class="form-control"
                                                            placeholder="Identification de l'invité"
                                                            formControlName="nomprenoms" readonly>
                                                        <input type="text" class="form-control" formControlName="id"
                                                            hidden>
                                                        <input type="text" class="form-control"
                                                            formControlName="participant_id" hidden>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="w3-container">
                                                <div class="w3-container">
                                                    <div class="w3-panel w3-pale-green">
                                                        <h3>Niveau activé </h3>
                                                        <a class="w3-animate-bottom"
                                                            style="font-size: 20px; color:red">{{msgAtape}}</a>
                                                    </div>
                                                </div>

                                                <div class="w3-container">

                                                    <p>
                                                        <label class="w3-margin-right">1</label>
                                                        <input class="w3-radio" type="radio" value="1"
                                                            formControlName="niveau" id="niveau1" *ngIf="numEtape==0">
                                                        <label class="w3-margin-left" for="niveau1">Inscription</label>
                                                        <i class="fas fa-check w3-margin-left w3-green"
                                                            *ngIf="numEtape>0"></i>
                                                    </p>
                                                    <p>
                                                        <label class="w3-margin-right">2</label>
                                                        <input class="w3-radio" type="radio" value="2"
                                                            formControlName="niveau" id="niveau2" *ngIf="numEtape==1">
                                                        <label class="w3-margin-left" for="niveau2">En attente des
                                                            votes</label>
                                                        <i class="fas fa-check w3-margin-left w3-green"
                                                            *ngIf="numEtape>1"></i>
                                                    </p>
                                                    <p>
                                                        <label class="w3-margin-right">3</label>
                                                        <input class="w3-radio" type="radio" value="3"
                                                            formControlName="niveau" id="niveau3" *ngIf="numEtape==2">
                                                        <label class="w3-margin-left" for="niveau3">Lancer le
                                                            vote</label>
                                                        <i class="fas fa-check w3-margin-left w3-green"
                                                            *ngIf="numEtape>2"></i>
                                                    </p>
                                                    <p>
                                                        <label class="w3-margin-right">4</label>
                                                        <input class="w3-radio" type="radio" value="4"
                                                            formControlName="niveau" id="niveau4" *ngIf="numEtape==3">
                                                        <label class="w3-margin-left" for="niveau4">Terminer le
                                                            vote</label>
                                                        <i class="fas fa-check w3-margin-left w3-green"
                                                            *ngIf="numEtape>3"></i>
                                                    </p>

                                                </div>


                                            </div>
                                            <hr>
                                            <div class="w3-container w3-center">
                                                <div class="form-group">
                                                    <button class="btn btn-success" type="submit"
                                                        [disabled]="!etapeForm.valid"
                                                        *ngIf="numEtape<4">Enregistrer</button>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="w3-half w3-container">
                                        <div class="w3-container">
                                            <div class="row " *ngIf="image">
                                                <div class="image" style="margin: auto;">
                                                    <img src="{{image}}" class="elevation-2" alt="User Image"
                                                        style="height: 250px; width: 250px;margin-top: 40px;">
                                                </div>

                                            </div>
                                            <div class="col-12">
                                                <div class="form-group w3-padding">
                                                    <label>Thème developpé </label>
                                                    <textarea id="w3review" name="w3review" rows="4" cols="50"
                                                        class="form-control" readonly>{{theme}}</textarea>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer">
                                </div>
                            </form>
                        </div>
                        <!-- /.card -->


                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>