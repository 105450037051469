<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Utilisateurs</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/listeutilisateur">Liste des utilisateurs</a></li>
                    <li class="breadcrumb-item active">Utilisateur</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!--    <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i> Note:</h5>
                    Selectionnez dans la liste l'agent que vous voulez activer !!!
                </div>


                 Main content -->
                <!-- /.card -->

                <div class="card">
                    <div class="card-header" style="background-color: #086A87; color: #FFFF; height: 50px  ">
                        <h3 class="card-title">Edition utilisateurs</h3>
                        <button routerLink='/admin/admin/listeutilisateurs' type="button" class="btn btn-danger float-sm-right" style="margin-top: -5px;">Retour</button>

                    </div>
                    <!-- /.card-header -->

                    <div class="col-md-12">
                        <!-- general form elements -->
                        <div class="card card-outline card-info" style="margin-top: 3px;">
                            <!-- form start -->
                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->
                            <form [formGroup]="userForm" (ngSubmit)="onSubmitForm(userForm.value)">
                                <div class="card-body">
                                    <div class="form-group">
                                        <label>Direction</label>
                                        <select class="form-control select2" style="width: 100%;" formControlName="Util_IDDirection">
                                            <option value="">-- Choisir une Direction --</option>
                                            <option *ngFor="let direction of listeDirection;" value={{direction.Id_direction}}>
                                                {{direction.LibelleDirection}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Nom et Prénoms</label>
                                        <input type="text" class="form-control" formControlName="Id_Utilisateur" placeholder="Id" hidden>
                                        <input type="text" class="form-control" formControlName="Util_Nomprenoms" placeholder="Identification de l'utilisateur" required>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Email addresse</label>
                                                <input type="email" class="form-control" formControlName="Util_Email" placeholder="Enter email" required>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="tel">Téléphone</label>
                                                <input type="text" class="form-control" formControlName="Util_telephone" placeholder="Téléphone">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="poste">Poste</label>
                                                <input type="text" class="form-control" formControlName="Util_Poste" placeholder="Poste">
                                            </div>
                                        </div>
                                    </div>



                                    <div class="form-check">

                                        <input type="checkbox" class="form-check-input" formControlName="Util_tech" id="Util_tech">
                                        <label class="form-check-label" for="Util_tech">Technicien</label>
                                        <div style="margin-top: 10px;">
                                            <input type="checkbox" class="form-check-input" formControlName="Util_Admin" id="Util_Admin">
                                            <label class="form-check-label" for="Util_Admin" style="cursor:pointer;">Administrateur</label>
                                        </div>
                                        <div style="margin-top: 10px;">
                                            <input type="checkbox" class="form-check-input" formControlName="Util_BP" id="Util_BP">
                                            <label class="form-check-label" for="Util_BP" style="cursor:pointer;">Bonpour</label>
                                        </div>

                                    </div>
                                    <!--div class="form-check">
                                        <input type="checkbox" class="form-check-input" formControlName="Util_Admin" id="Util_Admin">
                                        <label class="form-check-label" for="Util_Admin" style="cursor:pointer;">Administrateur</label>
                                            </div-->
                                </div>
                                <!-- /.card-body -->

                                <div class="card-footer">
                                    <button class="btn btn-success" type="submit" [disabled]="!userForm.valid">Enregistrer</button>
                                    <button [disabled]="!userForm.valid" *ngIf='afficheReinit' class="btn btn-warning float-sm-right" type="button" (click)="reinitmdp(userForm.value)">Réinitialiser le mot
                                        de
                                        passe</button>


                                </div>
                            </form>
                        </div>
                        <!-- /.card -->


                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>