import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { TableaudebordComponent } from './tableaudebord/tableaudebord.component';
import { FooterComponent } from './footer/footer.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ListeutilisateursComponent } from './listeutilisateurs/listeutilisateurs.component';
import { UtilisateurComponent } from './utilisateur/utilisateur.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactsComponent } from './contacts/contacts.component';
import { MatCardModule } from '@angular/material/card';
import { ListeinviteComponent } from './listeinvite/listeinvite.component';
import { EditioninviteComponent } from './editioninvite/editioninvite.component';
import { ModerateurComponent } from './moderateur/moderateur.component';
import { AnimateurComponent } from './animateur/animateur.component';
import { ListeinvitevotingComponent } from './listeinvitevoting/listeinvitevoting.component';
import { EditionparticipantComponent } from './editionparticipant/editionparticipant.component';
import { SuiviComponent } from './suivi/suivi.component';
import { EditionsuiviComponent } from './editionsuivi/editionsuivi.component';
import { EditionmancheComponent } from './editionmanche/editionmanche.component';
import { ListemanchesComponent } from './listemanches/listemanches.component';
import { ControlComponent } from './control/control.component';



@NgModule({
  declarations: [AdminComponent,
    TableaudebordComponent,
    FooterComponent,
    ListeutilisateursComponent,
    UtilisateurComponent,
    ContactsComponent,
    ListeinviteComponent,
    EditioninviteComponent,
    ModerateurComponent,
    AnimateurComponent,
    ListeinvitevotingComponent,
    EditionparticipantComponent,
    SuiviComponent,
    EditionsuiviComponent,
    ListemanchesComponent,
    EditionmancheComponent,
    ControlComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule
  ]
})
export class AdminModule {


}
