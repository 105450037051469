import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class VoteService {
  urlG: string;
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient) {
    this.urlG = this.configService.urlg;
  }

  /*------------------MANCHE------------------*/
  listeManche(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/vote/listemanchevote.php');
  }
  mancheParID(id): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/vote/mancheparid.php?id=' + id);
  }
  mancheParDate(date): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/vote/manchepardate.php?date=' + date);
  }
  
  mancheEncours(): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/vote/mancheEncours.php');
  }
  
  creationmanche(manche: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/creationmanche.php', manche);
  }
  
  modificationManche(manche: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/msjmanche.php', manche);
  }
  
  supmanche(id) {
    return this.httpClient.get<any>(this.urlG + '/vote/supmanche.php?id=' + id);
  }
  /*------------------------------------*/

   /*------------------PRTICIPANT------------------*/
  listeParticipant(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/vote/listeparticipant.php');
  }

 listeParticipantParDate(date): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/vote/listeparticipantpardate.php?date=' + date);
  }

  participantParID(id): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/vote/participantparid.php?id=' + id);
  }
  
  creationparticipant(participant: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/creationparticipant.php', participant);
  }
  
  modificationparticipant(participant: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/msjparticipant.php', participant);
  }

   activeParticipant(participant: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/activeParticipant.php', participant);
  }
  
  supparticipant(id) {
    return this.httpClient.get<any>(this.urlG + '/vote/supparticipant.php?id=' + id);
  }
  /*------------------------------------*/

   /*------------------SUIVI------------------*/
  sauvEtape(etape): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/sauvEtape.php', etape);
  }

    initvote(data): Observable<any> {
      return this.httpClient.post<any>(this.urlG + '/vote/initvote.php', data);
    }
  
    termineparticipant(data): Observable<any> {
      return this.httpClient.post<any>(this.urlG + '/vote/termineParticipant.php', data);
    }
  /*------------------------------------*/
  
   /*------------------DECOMPTE ------------------*/
  decompte(mancheid, participantid): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/vote/baromettre.php?mancheid='+ mancheid + '&participantid=' + participantid);
  }

   /*------------------STAT ------------------*/
    statistique(mancheid, participantid): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/vote/statistiqueparparticipant.php?mancheid='+ mancheid + '&participantid=' + participantid);
  }
/*--MANAGER--*/
     statistiquemanger(data): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/vote/statistiquemanager.php', data );
  }


}