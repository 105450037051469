<!-- Content Header (Page header) -->
<div class="content-header" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">EDITION PARTICIPANTS</h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a routerLink="/manches">Liste des participants</a></li>
                    <li class="breadcrumb-item active">Edition participants</li>
                </ol>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<section class="content" [@simpleFadeAnimation]="'in'">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!--    <div class="callout callout-info">
                    <h5><i class="fas fa-info"></i> Note:</h5>
                    Selectionnez dans la liste l'agent que vous voulez activer !!!
                </div>


                 Main content -->
                <!-- /.card -->

                <div class="card">
                    <div class="card-header" style="background-color: #086A87; color: #FFFF; height: 50px  ">
                        <h3 class="card-title">Edition Participant</h3>
                        <button routerLink='/admin/admin/manches' type="button" class="btn btn-danger float-sm-right"
                            style="margin-top: -5px;">Retourner à la
                            liste</button>

                    </div>
                    <!-- /.card-header -->

                    <div class="col-md-12">
                        <!-- general form elements -->
                        <div class="card card-outline card-info" style="margin-top: 3px;">
                            <!-- form start -->
                            <!-- loader  -->
                            <div class="spinner4" *ngIf="isLoadingResults">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                            <!-- loader end -->

                            <form [formGroup]="mancheForm" (ngSubmit)="onSubmitForm(mancheForm.value)">
                                <div class="w3-row">
                                    <div class="w3-half w3-container">
                                        <div class="card-body ">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Date de l'émission <a style="color: red;">*</a></label>
                                                        <input type="text" class="form-control" formControlName="id"
                                                            placeholder="Id" hidden>
                                                        <input type="date" class="form-control" formControlName="date">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w3-half w3-container" style="margin-top: 45px;">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <input class="w3-check" type="checkbox" formControlName="actif">
                                                    <label class="w3-padding">Activer</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <input class="w3-check" type="checkbox"
                                                        formControlName="fermeStudio">
                                                    <label class="w3-padding">Fermer le studio</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer">
                                    <div class="w3-container w3-center">
                                        <div class="form-group">
                                            <button class="btn btn-success" type="submit"
                                                [disabled]="!mancheForm.valid">Enregistrer</button>
                                            <button class="btn btn-danger" type="text" routerLink='/admin/admin/manches'
                                                style="margin-left: 10px;">Quitter
                                                la fiche</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!-- /.card -->


                    </div>

                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.invoice -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>