import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigService } from '../../services/config.service';
import { VoteService } from 'src/app/services/vote.service';
import { AlertPromise } from 'selenium-webdriver';


@Component({
  selector: 'app-editionparticipant',
  templateUrl: './editionparticipant.component.html',
  styleUrls: ['./editionparticipant.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class EditionparticipantComponent implements OnInit {

  participantForm: FormGroup;
  isLoadingResults = false;
  unparticipant: any = [];
  id: string;
  afficheReinit = false;
  reponse: any;
  fileToUpload: File = null;
 // image: string = "assets/dist/img/images.png";
  image: string = "";
  moidf = false;
  trouve = false;
  
  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private voteService: VoteService,
    private configService: ConfigService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder) { 
    this.participantForm = this.formBuilder.group({
        id: [''],
        nomprenoms:['', Validators.required],
        theme: ['', Validators.required],
        manchevote_id: ['', Validators.required],
        actif: [''],
        rangpassage: ['', Validators.required],
        lienphoto: [''],
        lienphotoTxt: [''],
        date: ['']
      });
    }

  ngOnInit() {
    this.loadOneParticipant();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.participantForm.controls['lienphotoTxt'].setValue(files.item(0));
  }

  choixDate(date) {
    this.participantForm.controls['manchevote_id'].setValue("");
     this.trouve = false;
    this.voteService.mancheParDate(date).subscribe(ret => {
      this.participantForm.controls['manchevote_id'].setValue(ret.results[0].id);
     
      if (ret.results[0].id != "") {
        this.trouve = true;
      } else {
        this.trouve = false;
      }
    }, () => {
      this.trouve = false;
    });
  }

  loadOneParticipant() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.isLoadingResults = true;
    if (this.id !== '0') {
      this.moidf = true;
      this.voteService.participantParID(this.id).subscribe(reponse => {
        this.unparticipant = reponse;

        this.participantForm.controls['id'].setValue(this.unparticipant.results[0].id);
        this.participantForm.controls['nomprenoms'].setValue(this.unparticipant.results[0].nomprenoms);
        this.participantForm.controls['theme'].setValue(this.unparticipant.results[0].theme);
        this.participantForm.controls['manchevote_id'].setValue(this.unparticipant.results[0].manchevote_id);
        this.participantForm.controls['rangpassage'].setValue(this.unparticipant.results[0].rangpassage);
        this.participantForm.controls['lienphotoTxt'].setValue(this.unparticipant.results[0].lienphoto);
         this.participantForm.controls['date'].setValue(this.unparticipant.results[0].date);
        this.image = this.configService.urlg+'/'+this.unparticipant.results[0].lienphoto;
        if (this.unparticipant.results[0].actif !== 'true') {
          this.participantForm.controls['actif'].setValue(false);
        } else {
          this.participantForm.controls['actif'].setValue(true);
        }
        this.choixDate(this.unparticipant.results[0].date);
      });
        this.isLoadingResults = false;
    } else {
        this.isLoadingResults = false;
    }
  }

  onSubmitForm(f) {
      this.isLoadingResults = true;
      const participantFormData = new FormData();
      participantFormData.append('nomprenoms', f.nomprenoms);
      participantFormData.append('theme', f.theme);
      participantFormData.append('manchevote_id', f.manchevote_id);
      participantFormData.append('rangpassage', f.rangpassage);
      participantFormData.append('actif', f.actif);

      if (this.fileToUpload == null) {
        participantFormData.append('lienphoto', this.fileToUpload);
      } else {
        participantFormData.append('lienphoto', this.fileToUpload, this.fileToUpload.name);
      }


      if (this.id === '0') {
        this.voteService.creationparticipant(participantFormData).subscribe(result => {
          switch (result.success) {
            case true: {
              // statements;
              this.toastr.success(result.message);
              this.reinitform();
              break;
            }
            case false: {
              // statements;
              this.toastr.error(result.message);
              break;
            }
            default: {
              // statements;
              this.toastr.error(result.message);
              break;
            }

          }
          this.isLoadingResults = false;

        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
        });
      } else {
        participantFormData.append('id', f.id);
        this.voteService.modificationparticipant(participantFormData).subscribe(result => {
          switch (result.success) {
            case true: {
              // statements;
              this.toastr.success(result.message);
              break;
            }
            case false: {
              // statements;
              this.toastr.error(result.message);
              break;
            }
            default: {
              // statements;
              this.toastr.error(result.message);
              break;
            }

          }
          this.isLoadingResults = false;
          this.location.back();
          //this.router.navigate(['/listeutilisateur']);
        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.isLoadingResults = false;
        });
      }


  }

  reinitform() {
    this.participantForm.reset();
  }


}