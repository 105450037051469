import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigService } from '../../services/config.service';
import { VoteService } from 'src/app/services/vote.service';

@Component({
  selector: 'app-editionsuivi',
  templateUrl: './editionsuivi.component.html',
  styleUrls: ['./editionsuivi.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class EditionsuiviComponent implements OnInit {

  etapeForm: FormGroup;
  isLoadingResults = false;
  uneEtape: any = [];
  id: string;
  afficheReinit = false;
  reponse: any;
  fileToUpload: File = null;
 // image: string = "assets/dist/img/images.png";
  image = "";
  theme = "";
  msgAtape = "0 - Aucune étape activée";
  numEtape: any;
  attente = false;

  constructor(
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private voteService: VoteService,
    private configService: ConfigService,
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder) { 
      this.etapeForm = this.formBuilder.group({
        id: ['',Validators.required],
        niveau: ['', Validators.required],
        participant_id: ['',Validators.required],
        nomprenoms: ['']
      });
    }
 
  
  ngOnInit() {
    this.loadEtape();
  }

  loadEtape() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.isLoadingResults = true;
    if (this.id !== '0') {
      this.voteService.mancheParID(this.id).subscribe(reponse => {
        this.uneEtape = reponse;

        this.etapeForm.controls['id'].setValue(this.uneEtape.results[0].id);
        this.etapeForm.controls['niveau'].setValue(this.uneEtape.results[0].numero_etape);
        this.etapeForm.controls['participant_id'].setValue(this.uneEtape.results[0].participant_id);

        this.etapeForm.controls['nomprenoms'].setValue(this.uneEtape.results[0].nomprenoms);
        this.theme=this.uneEtape.results[0].theme;
        this.image = this.configService.urlg + '/' + this.uneEtape.results[0].lienphoto;
        this.numEtape = this.uneEtape.results[0].numero_etape;

        //if (this.uneEtape.results[0].numero_etape = 0) { this.msgAtape = this.uneEtape.results[0].numero_etape + " - Aucune étape active " }
        if (this.uneEtape.results[0].numero_etape == 1) { this.msgAtape = this.uneEtape.results[0].numero_etape + " - Inscription " }
        if (this.uneEtape.results[0].numero_etape == 2) { this.msgAtape = this.uneEtape.results[0].numero_etape + " - En attente du vote " }
        if (this.uneEtape.results[0].numero_etape == 3) { this.msgAtape = this.uneEtape.results[0].numero_etape + " - Vote encours " }
        if (this.uneEtape.results[0].numero_etape == 4) { this.msgAtape = this.uneEtape.results[0].numero_etape + " - Terminé " }
        //alert(this.uneEtape.results[0].numero_etape);
      });
        this.isLoadingResults = false;
    } else {
        this.isLoadingResults = false;
    }
  }

  onSubmitForm(f) {
   // alert(f.niveau);
      this.isLoadingResults = true;
      const etapeFormData = new FormData();
            etapeFormData.append('id', f.id);
            etapeFormData.append('niveau', f.niveau);
            etapeFormData.append('participant_id', f.participant_id);
    
    if (f.niveau == 2 && this.attente == false) {
      this.attente = true;
      const initFormData = new FormData();
            initFormData.append('id', f.id);
            initFormData.append('participant_id', f.participant_id);
    
      this.voteService.initvote(initFormData).subscribe(ret => {
         if (ret.success == false) {
            this.toastr.error('Erreur dans l\'initialisation des votants');
            return;
          }
      });
    }
    
        if (f.niveau == 4 && this.numEtape == 1) {
      const termineFormData = new FormData();
            termineFormData.append('id', f.participant_id);
    
      this.voteService.termineparticipant(termineFormData).subscribe(ret => {
         if (ret.success == false) {
            this.toastr.error('Erreur dans l\'initialisation des votants');
            return;
          }
      });
          this.router.navigateByUrl("/admin/admin/suivi");
            
        }
    
        this.voteService.sauvEtape(etapeFormData).subscribe(result => {
          this.loadEtape(); 
          this.isLoadingResults = false;
          this.toastr.success('Etape validée');

        }, () => {
          this.toastr.error('Erreur de connexion au serveur');
          this.loadEtape(); 
          this.isLoadingResults = false;
        });
  


  }

}