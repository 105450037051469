<section class="content-header" [@simpleFadeAnimation]="'in'">


    <div>
        <!--class="content-wrapper"-->


        <section class="content">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-lg-3 col-12">

                        <div class="card card-warning card-outline">
                            <div class="row">
                                <div class="col-6" style="padding: 5px ;">
                                    <button class="btn btn-success btn-block"
                                        (click)="Raffraichitb()">Actualiser</button>
                                </div>
                                <div class="col-6" style="padding: 5px ;">
                                    <button class="btn btn-warning btn-block" (click)="retour()">Retour</button>
                                </div>
                            </div>

                            <div class="card-body box-profile">
                                <div class="text-center">
                                    <img class="profile-user-img img-fluid" src="{{image}}" alt="User profile picture"
                                        style="width:180px ;">
                                </div>
                                <h2 class="inner profile-username text-center font-weight-bold">{{partic[0].nomprenoms}}
                                </h2>
                                <!--div class="text-muted text-center">Software Engineer</div-->
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-9 col-12">
                        <div class="card card-warning card-outline">
                            <div class="card-body box-profile">
                                <div class="row">
                                    <div class="col-md-9">
                                        <div class="text-left">
                                            <h2 class="inner profile-username text-left font-weight-bold text-danger">
                                                THEME</h2>

                                            <h2 class="inner profile-username text-left font-weight-bold">
                                                {{partic[0].theme}}</h2>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="text-right">
                                            <img src="assets/imgx/logo.png" alt="User profile picture"
                                                style="width:180px ;">
                                        </div>
                                    </div>

                                    <div class="col-md-12 text-center text-info" style="padding-top: 10px;">
                                        <span class="font-weight-bold display-1 ">{{pourcentage}}<sup
                                                class="display-4">%</sup></span>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>


                <!--div class="row" style="height: 60px;">
                    <div class="col-4">
                        <div class="info-box mb-3" style="padding-top: 20px; padding-bottom: 20px;">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">TOTAL PARTICIPANTS</span>
                                <span style="height: 10px; color: white;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">{{nbreTotal}}</span>
                            </div>

                        </div>

                    </div>
                    <div class="col-4">
                        <div class="info-box mb-3" style="padding-top: 20px; padding-bottom: 20px;">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">EN STUDIO</span>
                                <span style="height: 10px; color: white;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">{{nbreTotalStudio}}</span>
                            </div>

                        </div>

                    </div>
                    <div class="col-4">
                        <div class="info-box mb-3" style="padding-top: 20px; padding-bottom: 20px;">
                            <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">TELESPECTATEURS</span>
                                <span style="height: 10px; color: white;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">{{nbreTotalTelespectateur}}</span>
                            </div>

                        </div>

                    </div>
                </div-->

                <div class="row" style="height: 60px; margin-top: 40px;">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="info-box mb-3" style="background-color: aquamarine;">
                            <span class="info-box-icon bg-success elevation-1"><i class="fas fa-thumbs-up"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">Total</span>
                                <span style="height: 10px; color: aquamarine;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">
                                    {{pourcentageJaime}} %</span>
                            </div>
                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">Studio</span>
                                <span style="height: 10px; color: aquamarine;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">
                                    {{pourcentageJaimeStudio}} %</span>
                            </div>

                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">Téléspectateurs</span>
                                <span style="height: 10px; color: aquamarine;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">
                                    {{pourcentageJaimeTelespectateur}} %</span>
                            </div>

                        </div>

                    </div>

                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="info-box mb-3" style="background-color: #ffccbc;">
                            <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-down"></i></span>

                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">Total</span>
                                <span style="height: 10px; color:#ffccbc;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">
                                    {{pourcentageJaimePas}} %</span>
                            </div>

                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">Studio</span>
                                <span style="height: 10px; color: #ffccbc;">ddsddddddddfff</span>
                                <span class="info-box-number" style="font-size: 40px">
                                    {{pourcentageJaimePasStudio}} %</span>
                            </div>

                            <div class="info-box-content">
                                <span class="info-box-text" style="font-size: 20px;">Téléspectateurs</span>
                                <span style="height: 10px; color:#ffccbc;">ddsddddddddfff</span>
                                <!--span class="info-box-number" style="font-size: 40px">{{nbreJaimePasTelespectateur}} -
                                                                {{pourcentageJaimePasTelespectateur}} %</span-->
                                <span class="info-box-number" style="font-size: 40px">
                                    {{pourcentageJaimePasTelespectateur}} %</span>
                            </div>

                        </div>

                    </div>
                </div>


                <div class="row" style="margin-top: 60px;">

                    <div class="col-md-6 col-12">

                        <div class="card card-widget widget-user">

                            <div class="widget-user-header text-white" style="background-color:#0A58CA ;">
                                <h3 class="widget-user-username" style="font-size: 30px;">HOMMES</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="assets/imgx/hommeV.jpeg" alt="User Avatar">
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-4 ">
                                        <div class="description-block">
                                            <span class="description-header" style="font-size: 30px;">Total</span>
                                            <p style="height: 10px; color: white;">ddsddddddddfff</p>
                                            <span class="description-text"
                                                style="font-size: 30px;">{{pourcentageHomme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header"><i class="fas fa-thumbs-up text-success"
                                                    style="font-size: 30px;"></i>
                                            </h5>
                                            <p style="height: 10px; color: white;">ddsddddddddfff</p>
                                            <span class="description-text"
                                                style="font-size: 30px;">{{pourcentageJaimeHomme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4">
                                        <div class="description-block">
                                            <h5 class="description-header"><i class="fas fa-thumbs-down text-danger"
                                                    style="font-size: 30px;"></i></h5>
                                            <p style="height: 10px; color: white;">ddsddddddddfff</p>
                                            <span class="description-text"
                                                style="font-size: 30px;">{{pourcentageJaimePasHomme}}%</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="col-md-6 col-12">

                        <div class="card card-widget widget-user">

                            <div class="widget-user-header text-white" style="background-color: #D63384;">
                                <h3 class="widget-user-username" style="font-size: 30px;">FEMMES</h3>
                            </div>
                            <div class="widget-user-image">
                                <img class="img-circle elevation-2" src="assets/imgx/femmeV.jpeg" alt="User Avatar">
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header" style="font-size: 30px;">Total</h5>
                                            <p style="height: 10px; color: white;">ddsddddddddfff</p>
                                            <span class="description-text"
                                                style="font-size: 30px;">{{pourcentageFemme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4 border-right">
                                        <div class="description-block">
                                            <h5 class="description-header" style="font-size: 30px;"><i
                                                    class="fas fa-thumbs-up text-success"></i>
                                            </h5>
                                            <p style="height: 10px; color: white;">ddsddddddddfff</p>
                                            <span class="description-text"
                                                style="font-size: 30px;">{{pourcentageJaimeFemme}}%</span>
                                        </div>

                                    </div>

                                    <div class="col-sm-4">
                                        <div class="description-block">
                                            <h5 class="description-header" style="font-size: 30px;"><i
                                                    class="fas fa-thumbs-down text-danger"></i></h5>
                                            <p style="height: 10px; color: white;">ddsddddddddfff</p>
                                            <span class="description-text"
                                                style="font-size: 30px;">{{pourcentageJaimePasFemme}}%</span>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div class="row">
                    <div class="col-lg-6 col-12">

                        <div class="card card-dark card-outline">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="far fa-chart-bar"></i>
                                    PARTICIPANTS PAR PAYS
                                </h3>
                            </div>
                            <div class="card-body">
                                <figure class="highcharts-figure">

                                    <!-- loader  -->
                                    <div class="spinner4" *ngIf="isLoadingResults">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>

                                    <div id="container"></div>
                                </figure>
                            </div>

                        </div>



                    </div>

                    <div class="col-lg-6 col-12">

                        <div class="card card-dark card-outline">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="far fa-chart-bar"></i>
                                    PARTICIPANTS PAR TRANCHE D'AGE
                                </h3>
                            </div>
                            <div class="card-body">
                                <figure class="highcharts-figure">

                                    <!-- loader  -->
                                    <div class="spinner4" *ngIf="isLoadingResultsSite">
                                        <div class="bounce1"></div>
                                        <div class="bounce2"></div>
                                        <div class="bounce3"></div>
                                    </div>


                                    <!-- /.card-body -->
                                    <div id="containerSite"></div>
                                </figure>
                            </div>

                        </div>



                    </div>
                </div>

            </div>
        </section>

    </div>

</section>