import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { InviteService } from '../../services/invite.service';
import { VoteService } from 'src/app/services/vote.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class ControlComponent implements AfterViewInit, OnInit {
  Participants: any = [];
  data: any = [];
  displayedColumns: string[] = ['votedu', 'nomparticipant', 'theme', 'rang','encours', 'boutons'];
  dataSource: MatTableDataSource<any>;
  idParticipant = '';
  isLoadingResults = true;
  dateDujour = new Date();
  dateChoisi;
  estactif = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
     private toastr: ToastrService,
    private voteService: VoteService,
    private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource(this.Participants);
  }

  ngOnInit() {
    this.dateChoisi = this.datePipe.transform(this.dateDujour, 'yyyy-MM-dd');
     this.participantsPardate(this.dateChoisi)
  }

  question(id) {
    this.idParticipant = id;
  }
  
  activation() {
      this.isLoadingResults = true;
      const participantFormData = new FormData();
      participantFormData.append('id', this.idParticipant );
      participantFormData.append('manchevote_id', this.dataSource.data[0].manchevote_id);
    this.voteService.activeParticipant(participantFormData).subscribe(ret => {
      this.listeParticipants();
    });
  }

  participantsPardate(date) {
    this.voteService.listeParticipantParDate(date).subscribe(ret => {
      this.data = ret.results;
       this.dataSource.data = this.data;
      this.isLoadingResults = false;
     
    });
  }

  listeParticipants() {
//alert(this.dateDujour);
    this.participantsPardate(this.dateChoisi);
   /* this.voteService.listeParticipant().subscribe(reponse => {
      this.Participants = reponse;
      this.data = this.Participants.results;
      this.dataSource.data = this.data;
      this.isLoadingResults = false;
    });*/
  }

  ngAfterViewInit() {
   // this.listeInvite();
     this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

