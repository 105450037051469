<div [@simpleFadeAnimation]="'in'">
    <!-- Content Wrapper. Contains page content -->

    <!-- Content Header (Page header) -->
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Tableau de Bord</h1>
                </div>
                <!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="javascript::">Home</a></li>
                        <li class="breadcrumb-item active">Tableau de Bord</li>
                    </ol>
                </div>
                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
        <div class="container-fluid">
            <div class="row col-12">
                <div class="col-lg-2 col-12">
                    <!-- Small boxes (Stat box) -->

                    <div class="col-lg-12 col-12">
                        <!-- small box -->
                        <div class="small-box bg-info">

                            <div class="inner">
                                <a href="javascript::" style="color: white;">
                                    <h3>0</h3>
                                    <p>Ticket(s)</p>
                                    <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- ./col -->
                    <div class=" col-lg-12 col-12 ">
                        <!-- small box -->
                        <div class="small-box bg-success ">
                            <div class="inner ">
                                <a href="javascript::" style="color: white;">
                                    <h3>0</h3>
                                    <p>Ticket(s) Encours</p>
                                    <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- ./col -->
                    <div class="col-lg-12 col-12 ">
                        <!-- small box -->
                        <div class="small-box bg-warning ">
                            <div class="inner ">
                                <a href="javascript::" style="color: rgb(10, 9, 9);">
                                    <h3>0</h3>
                                    <p>Ticket(s) Terminé(s)</p>
                                    <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.row -->
                <!-- Main row -->
                <div class="col-lg-10 col-12 ">
                    <div id="container"></div>
                </div>
                <!-- /.row (main row) -->
            </div>
            <div class="row col-12">
                <div class="row col-lg-8 col-12">
                    <div class="row col-12">

                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box" style="background-color: #FFDC64;">
                                <div class="inner ">
                                    <a href="javascript::" style="color:#977500;">
                                        <h3>0</h3>
                                        <p>Incident(s)</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box bg-info ">
                                <div class="inner ">
                                    <a href="javascript::" style="color: rgb(248, 242, 242);">
                                        <h3>0</h3>
                                        <p>Problème(s)</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box bg-info ">
                                <div class="inner ">
                                    <a href="javascript::" style="color: rgb(248, 242, 242);">
                                        <h3>0</h3>
                                        <p>Service(s)</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <div class="small-box" style="background-color: #FFDC64;">
                                <div class="inner ">
                                    <a href="javascript::" style="color:#977500;">
                                        <h3>0</h3>
                                        <p>Tâche(s)</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">

                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box" style="background-color: #FFDC64;">
                                <div class="inner ">
                                    <a href="javascript::" style="color:#977500;">
                                        <h3>0</h3>
                                        <p>Application</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box bg-info ">
                                <div class="inner ">
                                    <a href="javascript::" style="color: rgb(248, 242, 242);">
                                        <h3>0</h3>
                                        <p>Bureautique</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box bg-info ">
                                <div class="inner ">
                                    <a href="javascript::" style="color: rgb(248, 242, 242);">
                                        <h3>0</h3>
                                        <p>Matériel et Accessoire</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <div class="small-box" style="background-color: #FFDC64;">
                                <div class="inner ">
                                    <a href="javascript::" style="color:#977500;">
                                        <h3>0</h3>
                                        <p>Téléphone</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col-12">

                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box" style="background-color: #FFDC64;">
                                <div class="inner ">
                                    <a href="javascript::" style="color:#977500;">
                                        <h3>0</h3>
                                        <p>Basse</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box bg-info ">
                                <div class="inner ">
                                    <a href="javascript::" style="color: rgb(248, 242, 242);">
                                        <h3>0</h3>
                                        <p>Normal</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <!-- small box -->
                            <div class="small-box bg-info ">
                                <div class="inner ">
                                    <a href="javascript::" style="color: rgb(248, 242, 242);">
                                        <h3>0</h3>
                                        <p>Elevé</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 ">
                            <div class="small-box" style="background-color: #FFDC64;">
                                <div class="inner ">
                                    <a href="javascript::" style="color:#977500;">
                                        <h3>0</h3>
                                        <p>Urgent</p>
                                        <i class="fas fa-arrow-circle-right" style="font-size: 25px"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-12">
                    <!-- USERS LIST -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">TECHNICIENS ACTIFS </h3>


                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <ul class="users-list clearfix">
                                <li>
                                    <img src="assets/dist/img/user1-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Alexander Pierce</a>
                                    <span class="users-list-date">35 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user8-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Norman</a>
                                    <span class="users-list-date">20 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user7-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Jane</a>
                                    <span class="users-list-date">12 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user6-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">John</a>
                                    <span class="users-list-date">12 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user2-160x160.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Alexander</a>
                                    <span class="users-list-date">13 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user7-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Sarah</a>
                                    <span class="users-list-date">14 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user4-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Nora</a>
                                    <span class="users-list-date">15 pts</span>
                                </li>
                                <li>
                                    <img src="assets/dist/img/user8-128x128.jpg" alt="User Image">
                                    <a class="users-list-name" href="javascript::">Nadia</a>
                                    <span class="users-list-date">15 pts</span>
                                </li>
                            </ul>
                            <!-- /.users-list -->
                        </div>
                        <!-- /.card-body -->
                        <div class="card-footer text-center">
                            <a href="javascript::">Voir tous les Techniciens</a>
                        </div>
                        <!-- /.card-footer -->
                    </div>
                    <!--/.card -->
                </div>
            </div>
        </div>
        <!-- /.container-fluid -->
    </section>
    <!-- /.content -->

    <!-- /.content-wrapper -->
    <footer class="main-footer ">
        <strong>Copyright &copy; 2014-2019 <a href="http://adminlte.io ">AdminLTE.io</a>.</strong> All rights reserved.
        <div class="float-right d-none d-sm-inline-block ">
            <b>Version</b> 3.0.0-rc.3
        </div>
    </footer>
</div>