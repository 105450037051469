import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class AdminComponent implements OnInit {
  idUtilisateur: string;
  nomprenoms: string;
  nbreAttrib = 0;
  result: any = [];
  image: string;
  MesTickets: boolean = false;
  MesAttributions: boolean = false;
  GestionTickets: boolean = false;
  Referentiel: boolean = false;
  Administration: boolean = false;
  moderateur: boolean = false;
  animateur: boolean = false;

  Administrationvote: boolean = true;

  title = 'NEO';
  isAuth = 'n';
  registr = false;


  constructor(
    public configService: ConfigService,
    private router: Router
  ) { }



  ngOnInit() {
    this.idUtilisateur = localStorage.getItem('id');
    this.nomprenoms = localStorage.getItem('nomprenoms');
    this.image = localStorage.getItem('image');

    if (localStorage.getItem('Util_Admin') == 'true' || localStorage.getItem('Util_Admin') == '1') {
      this.Administration = true;
      this.animateur = true;
      this.moderateur = true;
    } else if (localStorage.getItem('util_animateur') == 'true' || localStorage.getItem('util_animateur') == '1') {
      this.Administration = false;
      this.animateur = true;
      this.moderateur = false;
      this.router.navigateByUrl('/admin/admin/animateur');
    } else if (localStorage.getItem('util_moderateur') == 'true' || localStorage.getItem('util_moderateur') == '1') {
      this.Administration = false;
      this.animateur = false;
      this.moderateur = true;
      this.router.navigateByUrl('/admin/admin/moderateur');
    }

    
    // setInterval(() => this.NbreAttribution(this.idUtilisateur), 5600);
  }


  deconnexion() {
    localStorage.clear();
    /*localStorage.removeItem('isConnected');
    localStorage.removeItem('jwt');
    localStorage.removeItem('email');
    localStorage.removeItem('nomprenoms');
    localStorage.removeItem('id');*/
    this.router.navigateByUrl('/auth');
  }
}
