import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ToastrService } from 'ngx-toastr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { VoteService } from 'src/app/services/vote.service';


@Component({
  selector: 'app-listeinvitevoting',
  templateUrl: './listeinvitevoting.component.html',
  styleUrls: ['./listeinvitevoting.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(1000)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(1000, style({ opacity: 0 })))
    ])
  ]
})
export class ListeinvitevotingComponent implements AfterViewInit, OnInit {
  Participants: any = [];
  data: any = [];
  displayedColumns: string[] = ['votedu', 'nomparticipant', 'theme', 'rang','encours', 'boutons'];
  dataSource: MatTableDataSource<any>;
  IDSuppression = '';
  isLoadingResults = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private toastr: ToastrService,
    private voteService: VoteService) {
    this.dataSource = new MatTableDataSource(this.Participants);
  }

  ngOnInit() {
      this.listeParticipants();
  }

  listeParticipants() {
    this.voteService.listeParticipant().subscribe(reponse => {
      this.Participants = reponse;
      this.data = this.Participants.results;
      this.dataSource.data = this.data;
      this.isLoadingResults = false;
    });
  }

  // reccuper l'id à supprimer lors de l'ouverture du modal de question
  questionSupprime(id) {
    this.IDSuppression = id;
  }

  // Supprime l'enregistrement
  supprime() {
    this.voteService.supparticipant(this.IDSuppression).subscribe(reponse => {
      switch (reponse.success) {
        case true: {
          // statements;
          this.toastr.success(reponse.message);
          this.listeParticipants();
          break;
        }
        case false: {
          // statements;
          this.toastr.error(reponse.message);
          break;
        }
        default: {
          // statements;
          this.toastr.error('Erreur de connexion au serveur');
          break;
        }

      }
    });
    this.IDSuppression = '';
  }

  ngAfterViewInit() {
   // this.listeInvite();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}

