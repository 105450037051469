import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  urlG: string;
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient) {
    this.urlG = this.configService.urlg;
  }

  listeInvite(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/invite/listeinvite.php');
  }
  inviteparid(id: any): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/invite/inviteparid.php?id='+id);
  }
  creationinvite(invite: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/invite/creationinvite.php', invite);
  }
  msjinvite(invite: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/invite/msjinvite.php', invite);
  }
  supinvite(id) {
    return this.httpClient.delete<any>(this.urlG + '/invite/supinvite.php?id=' + id);
  }

  inviteencours(){
    return this.httpClient.get<any[]>(this.urlG + '/questions/chargementinvite.php')
  }
  creationquestion(question: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/questions/creationquestion.php', question);
  }
  modificationquestion(question: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/questions/modificationquestion.php', question);
  }
  listequestion(id: any): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/questions/chargementquestions.php?id='+ id);
  }
  listequestionanimateur(id: any): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/questions/chargementquestionsanimateur.php?id='+ id);
  }
  updateval(id: any): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/questions/updateval.php?id='+ id);
  }
/*
  listeUtilisateurDsi(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/utilisateurs/listeutilisateurDsi.php');
  }

 
  updateUtilisateur(utilisateur: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/utilisateurs/updateutilisateur.php', utilisateur);
  }

  modifmdp(utilisateur: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/utilisateurs/modifmdp.php', utilisateur);
  }

  demandeEnr(utilisateur: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/utilisateurs/demandeEnr.php', utilisateur);
  }


  reinitmdp(utilisateur: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/utilisateurs/reinitmdp.php', utilisateur);
  }

  mdpoublie(utilisateur: any): Observable<any> {
    return this.httpClient.post<any>(this.urlG + '/utilisateurs/mdpoublie.php', utilisateur);
  }

  readoneUtilisateur(Id_Utilisateur): Observable<any> {
    return this.httpClient.get<any>(this.urlG + '/utilisateurs/Oneutilisateur.php?Id_Utilisateur=' + Id_Utilisateur);
  }

 

  login(info: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.urlG + '/connexion/login.php', info);
  }

  verifemaillocal(Util_Email: any): Observable<any[]> {
    return this.httpClient.get<any[]>(this.urlG + '/utilisateurs/verifiutilisateurparemail.php?Util_Email=' + Util_Email);
  }
*/
}
